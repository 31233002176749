var $class="se2--utilities",$name="utilities",$path="app/components/utilities/validateCountryPhoneInput.js",$this={$class,$name,$path,};import { css } from 'configs';
import first from 'lodash/first';

const formGroupClassName = 'form-group';

const validateCountryPhoneInput = (form) => {
  const emailField = form.querySelector('.input-email');
  const phoneField = form.querySelector('.pn-input-phone');
  const madatoryFieldErr = form.querySelector('.madatoryFieldErr');
  const phoneLength = phoneField?.value.length;

  if (form.classList.contains('formEmailSignUp')) {
    if (!emailField?.value && phoneLength < 10) {
      first(emailField)?.focus();
      emailField?.closest(`.${formGroupClassName}`).classList.add('madatoryField');
      phoneField?.closest(`.${formGroupClassName}`).classList.add('madatoryField');
      madatoryFieldErr?.classList.add(css.classNames.show);
      return false;
    }
    if (emailField?.value && phoneLength < 10) {
      phoneField.value = '';
    }
    emailField?.closest(`.${formGroupClassName}`).classList.remove('madatoryField');
    phoneField?.closest(`.${formGroupClassName}`).classList.remove('madatoryField');
    madatoryFieldErr?.classList.remove(css.classNames.show);
    return true;
  }
  if (!phoneField?.hasAttribute('required')) {
    if (phoneLength < 10) {
      phoneField.value = '';
    }
  } else if (phoneLength < 10) {
    phoneField?.closest(`.${formGroupClassName}`).classList.add('invalid');
    return false;
  }

  return true;
};

export default validateCountryPhoneInput;
