var $name="analytics/chat",$path="app/modules/analytics/chat.js",$this={$name,$path,};/**
 * @param { { chatAction: string } } variables
 */
const chatActionProvider = ({
  chatActionKey,
}, config) => ({
  chatAction: config.chatAction?.[chatActionKey] ?? chatActionKey,
});

// eslint-disable-next-line import/prefer-default-export
export { chatActionProvider };
