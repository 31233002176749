var $class="se2--utilities",$name="utilities",$path="app/components/utilities/prefillSelectFromMarketo.js",$this={$class,$name,$path,};import find from 'lodash/find';

function prefillSelectFromMarketo(field, value) {
  const selectedOption = find(field.options, { value });
  if (selectedOption) {
    selectedOption.selected = true;
    field.dispatchEvent(new Event('change'));
  }
}

export default prefillSelectFromMarketo;
