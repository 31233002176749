var $name="shell/engine/prepareSettings",$path="app/modules/shell/engine/prepareSettings.js",$this={$name,$path,};import { css } from 'configs';

import forEach from 'lodash/forEach';

import messaging from '../messaging';
import settings from '../settings';

export default function prepareSettings() {
  settings.load();

  document.dir = settings.direction;

  const { browser, device, system, version } = settings.platform;
  document.documentElement.classList.add(
    css.classNames.devices[device],
    css.classNames.systems[system],
    css.classNames.browsers[browser],
    `${css.classNames.browsers[browser]}-${version}`,
  );

  forEach(
    settings.subscribers,
    (subscriber, message) => {
      messaging.subscribeToMessage(message, subscriber);
    },
  );

  return () => forEach(
    settings.subscribers,
    (subscriber, message) => {
      messaging.unsubscribeFromMessage(message, subscriber);
    },
  );
}
