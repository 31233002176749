var $class="se2--input",$name="input",$path="app/partials/input/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

import { css } from 'configs';
import { emailPattern } from 'configs/validationPatterns';

export const toggleInvalid = (element, show) => element
  .closest('.form-group').classList.toggle(css.classNames.invalid, show);

const toggleIncorrect = (element, show) => element.closest('.form-group').classList.toggle('incorrect', show);

const onChange = ({ target }) => {
  if (target.classList.contains('input-email')) {
    if (emailPattern.test(target.value)) {
      toggleIncorrect(target, false);
    } else {
      toggleIncorrect(target, true);
    }
  } else {
    toggleInvalid(target, !target.checkValidity() && !target.value);
    toggleIncorrect(target, !target.checkValidity() && !!target.value);
  }
};

const onKeyDown = ({ target }) => {
  toggleInvalid(target, false);
  toggleIncorrect(target, false);
};
const checkIfEmail = (el) => {
  const setEmailType = el.querySelector('.input-email');
  setEmailType?.setAttribute('type', 'text');
};

export default shell.registerComponent($this, ({ addEventListener, mount }) => {
  mount((element) => {
    checkIfEmail(element);
    // addEventListener(element.closest('form'),
    //   'change',
    //   (event) => onFormChange(event, element));
    addEventListener(element, 'change', onChange);
    addEventListener(element, 'keydown', onKeyDown);
  });
});
