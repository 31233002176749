var $name="sha256",$path="app/utilities/sha256.js",$this={$name,$path,};/**
 * Hash a string and returns a promis of the final hexvalue.
 * https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
 * @param { string } message
 * @return { Promise }
 */

export default async function sha256(message) {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);
  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  // convert bytes to hex string
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

  return hashHex;
}
