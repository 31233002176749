var $class="se2--utilities",$name="utilities",$path="app/components/utilities/validateFormElements.js",$this={$class,$name,$path,};import { css } from 'configs';

import first from 'lodash/first';
import forEach from 'lodash/forEach';

import checkCheckboxGroupValidity from './checkCheckboxGroupValidity';
import checkIsIncorrect from './checkIsIncorrect';
import validateCaptchaResponse from './validateCaptchaResponse';
import validateCountryPhoneInput from './validateCountryPhoneInput';

const formGroupClassName = 'form-group';

function validateFormElements(form) {
  const customValidators = checkCheckboxGroupValidity(form);
  const checkIsIncorrectField = checkIsIncorrect(form);
  const captchaIsValid = validateCaptchaResponse(form);
  const checkIsFormEmailUp = validateCountryPhoneInput(form);
  const formIsValid = form.checkValidity() && customValidators && checkIsIncorrectField && checkIsFormEmailUp;
  if (!formIsValid) {
    const invalidFormFields = form.querySelectorAll(':invalid');
    first(invalidFormFields)?.focus();
    forEach(
      invalidFormFields,
      (el) => el.closest(`.${formGroupClassName}`)
        ?.classList.add(css.classNames.invalid),
    );
  } else {
    forEach(
      form.querySelectorAll(`.${css.classNames.invalid}`),
      (elem) => elem.classList.remove(css.classNames.invalid),
    );
  }

  return formIsValid && captchaIsValid;
}

export default validateFormElements;
