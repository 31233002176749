var $class="se2--utilities",$name="utilities",$path="app/components/utilities/validateCaptchaResponse.js",$this={$class,$name,$path,};import { css } from 'configs';

import {
  validationErrorMessageClassName,
  responseTokenClassName,
} from 'app/partials/captcha/config';

function validateCaptchaResponse(form) {
  const responseToken = form.querySelector(`.${responseTokenClassName}`);
  if (!responseToken) return true;
  const validationErrorMessage = form.querySelector(`.${validationErrorMessageClassName}`);
  validationErrorMessage?.classList
    .toggle(css.classNames.hide, !!responseToken?.value);
  return !!responseToken?.value;
}

export default validateCaptchaResponse;
