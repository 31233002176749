var $name="validateCSSVariable",$path="app/utilities/validateCSSVariable.js",$this={$name,$path,};import { css } from 'configs';

const variables = new Set(Object.values(css.variableNames));

function validateCSSVariable(name) {
  if (!variables.has(name)) {
    throw new Error(`CSS variable "${name}" is not defined in css config`);
  }
}

export default validateCSSVariable;
