var $class="se2--notification",$name="notification",$path="app/partials/notification/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';
import initializeSticky from 'app/modules/sticky/initializeSticky';
import setStickyAnimation from 'app/modules/sticky/setStickyAnimation';

import { messages } from 'configs';

export default shell.registerComponent($this, ({
  addEventListener,
  mount,
  publishMessage,
  subscribeToMessage,
}) => {
  const subscriptions = new Map();

  const displayNotificationMessage = (element) => {
    element.classList.remove('invisible');
    setTimeout(() => element.classList.add('invisible'), 5000);
  };

  const handleCloseBtnClick = ({ target }) => {
    target.closest('.notification-container').classList.add('invisible');
  };

  const initNotification = (element, eventListener) => {
    const hideNotification = () => {
      element.classList.add('invisible');
      publishMessage(messages.refreshTimelines);
    };
    const onScroll = () => {
      hideNotification();
    };
    const notificationHandler = ({ status, formId, message }) => {
      if (
        (element.dataset.formId === formId && (status === 'accepted' || status === 'redirected'))
        || message.length
      ) {
        displayNotificationMessage(element);
        eventListener(window, 'scroll', onScroll);
      }
    };

    return { notificationHandler, hideNotification, onScroll };
  };

  mount((element) => {
    const { notificationHandler, hideNotification, onScroll } = initNotification(element, addEventListener);
    subscriptions.set(element, { notificationHandler, hideNotification, onScroll });

    subscribeToMessage(messages.formSubmit, notificationHandler);
    subscribeToMessage(messages.cartUpdatedByProductSelector, notificationHandler);

    const closeBtn = element.querySelector('.close-btn');
    if (closeBtn) addEventListener(closeBtn, 'click', handleCloseBtnClick);

    const header = document.querySelector('header');
    if (header) addEventListener(header, 'click', hideNotification);

    initializeSticky(element);
    setStickyAnimation(element, element.previousElementSibling, subscribeToMessage);
  });
});
