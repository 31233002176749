var $name="index",$path="configs/index.js",$this={$name,$path,};const analytics = require('./analytics');
const breakpoints = require('./breakpoints');
const browserlist = require('./browserlist');
const browsers = require('./browsers');
const countryCodes = require('./countryCodes');
const css = require('./css');
const defaults = require('./defaults');
const delays = require('./delays');
const devices = require('./devices');
const directions = require('./directions');
const envs = require('./envs');
const form = require('./form');
const geolocation = require('./geolocation');
const gsapAnimation = require('./gsapAnimation');
const htmlEntities = require('./htmlEntities');
const imageNaturalSizes = require('./imageNaturalSizes');
const i18n = require('./i18n');
const keyNames = require('./keyNames');
const languageCodes = require('./languageCodes');
const messages = require('./messages');
const mouseButtons = require('./mouseButtons');
const namespaces = require('./namespaces');
const sortDirections = require('./sortDirections');
const sso = require('./sso');
const statuses = require('./statuses');
const systems = require('./systems');
const toMediaQuery = require('./utilities/toMediaQuery');
const utmKeys = require('./utmKeys');
const zIndexes = require('./zIndexes');

module.exports = {
  analytics,
  breakpoints,
  browserlist,
  browsers,
  countryCodes,
  css,
  defaults,
  delays,
  devices,
  directions,
  envs,
  form,
  geolocation,
  gsapAnimation,
  htmlEntities,
  imageNaturalSizes,
  i18n,
  keyNames,
  languageCodes,
  messages,
  mouseButtons,
  namespaces,
  sortDirections,
  sso,
  statuses,
  systems,
  toMediaQuery,
  utmKeys,
  zIndexes,
};
