var $name="getTaxonomy",$path="app/utilities/getTaxonomy.js",$this={$name,$path,};import { languageCodes } from 'configs';

import first from 'lodash/first';

import getSlugs from './getSlugs';

/**
 * Returns taxonomy for specified pathname:
 * all path segments without ones defining country and language codes.
 * @param { string } pathname
 * @returns { string[] }
 */
export default function getTaxonomy(pathname = window.location.pathname) {
  const slugs = getSlugs(pathname);
  return slugs.slice(
    first(slugs) === languageCodes.chinese
      ? 1
      : 2,
  );
}
