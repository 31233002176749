var $name="setAriaAttributes",$path="app/utilities/setAriaAttributes.js",$this={$name,$path,};import forEach from 'lodash/forEach';

function setAriaAttributes(element, ariaAttributes) {
  forEach(
    ariaAttributes,
    (value, key) => element.setAttribute(`aria-${key}`, value),
  );
}

export default setAriaAttributes;
