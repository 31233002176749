var $name="shell/engine/watchWindowResize",$path="app/modules/shell/engine/watchWindowResize.js",$this={$name,$path,};import { css } from 'configs';
import setCSSVariable from 'app/utilities/setCSSVariable';

function onWindowResize() {
  setCSSVariable(
    css.variableNames.windowHeightInner,
    `${document.documentElement.getBoundingClientRect().height}px`,
  );
}

export default function watchWindowResize() {
  window.addEventListener('resize', onWindowResize);
  onWindowResize();
  return () => window.addEventListener('resize', onWindowResize);
}
