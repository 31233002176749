var $class="se2--utilities",$name="utilities",$path="app/components/utilities/addUtmParamsToFormData.js",$this={$class,$name,$path,};import settings from 'app/modules/shell/settings';

import { utmKeys } from 'configs';

import forEach from 'lodash/forEach';

const utmHiddenInputClassName = 'utm-hidden-input';

function addUtmParamsToFormData(form) {
  forEach(
    form.querySelectorAll(`.${utmHiddenInputClassName}`),
    (field) => {
      const utmValue = localStorage.getItem(
        `${utmKeys[field.name]}_${settings.page.countryCode}`,
      );
      if (utmValue) {
        field.setAttribute('value', utmValue);
      }
    },
  );
}

export default addUtmParamsToFormData;
