var $name="delays",$path="configs/delays.js",$this={$name,$path,};module.exports = {
  ms100: 100,
  ms150: 150,
  ms300: 300,
  ms500: 500,
  s1: 1000,
  s3: 3000,
  s5: 5000,
  s10: 10000,
  s30: 30000,
};
