var $name="shell/engine/watchKeyboardUsage",$path="app/modules/shell/engine/watchKeyboardUsage.js",$this={$name,$path,};import messaging from 'app/modules/shell/messaging';
import { messages, css } from 'configs';

export default function watchKeyboardUsage() {
  document.body.addEventListener(
    'keydown',
    () => {
      document.body.classList.add(css.classNames.keyboard);
      messaging.publishMessage(messages.isKeyboardUsed, { isKeyboardUsed: true });
    },
  );
  document.body.addEventListener(
    'mousedown',
    () => {
      document.body.classList.remove(css.classNames.keyboard);
      messaging.publishMessage(messages.isKeyboardUsed, { isKeyboardUsed: false });
    },
  );
}
