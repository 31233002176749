var $name="lookupSlugs",$path="app/utilities/lookupSlugs.js",$this={$name,$path,};import every from 'lodash/every';
import find from 'lodash/find';

import getSlugs from './getSlugs';

/**
 * @param { { [key: string]: string } } config
 * @param { string[] } slugs
 * @param { string } [def]
 * @returns { string }
 */
export default function lookupSlugs(config, slugs, def = '') {
  const [, value] = find(
    Object
      .entries(config)
      // TODO: to use lodash here as well
      .sort(([{ length: a }], [{ length: b }]) => b - a),
    ([pattern]) => every(
      getSlugs(pattern),
      (part, index) => (
        part === slugs[index] || (part === '*' && index < slugs.length)
      ),
    ),
  ) ?? [def, def];
  return value;
}
