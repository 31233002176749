var $name="analytics/getEnvironment",$path="app/modules/analytics/getEnvironment.js",$this={$name,$path,};/**
 * @param { { environmentOrigins: object, defaults: { environment: string } } } config
 * @param { { origin: string } } location
 * @returns { string }
 */
export default function getEnvironment(config = {}, { origin } = window.location) {
  const entries = Object.entries(config?.environmentOrigins ?? {});
  const [environmentKey] = (
    entries.find(
      ([, origins]) => origins.some((candidate) => candidate === origin),
    ) ?? entries.find(
      ([, origins]) => origins.some((candidate) => candidate === '*'),
    )
  ) ?? [];
  return config.environment?.[environmentKey] ?? config.defaults?.environment;
}
