var $name="shell/Log",$path="app/modules/shell/Log.js",$this={$name,$path,};import padStart from 'lodash/padStart';

import settings from './settings';

const pad = (value, length) => padStart(value, length, '0');

const output = ({ source: { $path } }, method, message, args) => {
  if (settings.debug) {
    const date = new Date();
    console[method]( // eslint-disable-line no-console
      `${
        pad(date.getHours(), 2)
      }:${
        pad(date.getMinutes(), 2)
      }:${
        pad(date.getSeconds(), 2)
      }.${
        pad(date.getMilliseconds(), 3)
      } [${$path}] ${message}`,
      ...args,
    );
  }
};

/**
 * @implements { ILog }
 */
class Log {
  /**
 * Returns new instance of log object for specified component, module or partial.
 * Actual logging will be performed only if `debug` mode is enabled in settings.
 * @param { typeof $this } source
 * `$this` metavariable of source component, module or partial.
 * @returns { ILog }
 */
  constructor(source) {
    Object.defineProperty(this, 'source', { value: source });
  }

  error(message, ...args) {
    output(this, 'error', message, args);
  }

  info(message, ...args) {
    output(this, 'info', message, args);
  }

  warn(message, ...args) {
    output(this, 'warn', message, args);
  }
}

export default Log;
