var $name="sticky/calculateTotalStickyHeight",$path="app/modules/sticky/calculateTotalStickyHeight.js",$this={$name,$path,};import { metabarHeightValue, metabarHeightValueVersion8 } from 'app/components/HeaderSE/config';
import { isHeaderVersion8 } from 'app/components/HeaderSE/utilities/isHeaderVersion8';
import checkHeaderExistence from 'app/components/utilities/checkHeaderExistence';

import { breakpoints, css } from 'configs';
import parseDecimal from 'app/utilities/parseDecimal';
import setCSSVariable from 'app/utilities/setCSSVariable';
import findLast from 'lodash/findLast';

const stuckElementsSelector = '[data-stuck=true]';

function isDesktopBreakpoint() {
  return window.innerWidth >= breakpoints.xl.min;
}

function calculateTotalStickyHeight() {
  const lastStuckElement = findLast(
    document.querySelectorAll(stuckElementsSelector),
    (element) => window.getComputedStyle(element).display !== 'none',
  );

  const totalStickyHeight = lastStuckElement
    ? parseDecimal(lastStuckElement.style.top)
      + lastStuckElement.offsetHeight
      // eslint-disable-next-line no-nested-ternary
      - (checkHeaderExistence() && isDesktopBreakpoint()
        ? (isHeaderVersion8() ? metabarHeightValue : metabarHeightValueVersion8)
        : 0)
    : 0;

  setCSSVariable(
    css.variableNames.totalStickyHeight,
    `${totalStickyHeight}px`,
  );
}

export default calculateTotalStickyHeight;
