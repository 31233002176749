var $name="switchFocusToTargetElement",$path="app/utilities/switchFocusToTargetElement.js",$this={$name,$path,};import setTabIndex from 'app/utilities/setTabIndex';

const switchFocusToTargetElement = (targetElement, currentElement) => {
  if (targetElement != null && currentElement != null) {
    setTabIndex(currentElement, false);
    setTabIndex(targetElement, true);
    targetElement.focus();
  }
};

export default switchFocusToTargetElement;
