var $name="analytics/menuClick",$path="app/modules/analytics/menuClick.js",$this={$name,$path,};/**
 * @param { { menu_type: string}  } variables
 * @param { { menu_category_1: string}  } variables
 * @param { { menu_category_2: string}  } variables
 * @param { { menu_category_3: string}  } variables
 * @param { { menu_category_4: string}  } variables
 * @param { { menu_category_5: string}  } variables
*/
/* eslint-disable camelcase */
function menuClickProvider({
  menu_type = '',
  menu_category_1 = '',
  menu_category_2 = '',
  menu_category_3 = '',
  menu_category_4 = '',
  menu_category_5 = '',
} = {}) {
  return {
    menu_type,
    menu_category_1,
    menu_category_2,
    menu_category_3,
    menu_category_4,
    menu_category_5,
  };
}
/* eslint-disable camelcase */
// eslint-disable-next-line import/prefer-default-export
export { menuClickProvider };
