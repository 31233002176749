var $class="se2--product-selector",$name="productSelector",$path="app/partials/productSelector/index.js",$this={$class,$name,$path,};import ajax from 'app/modules/ajax';
import analytics from 'app/modules/analytics';
import shell from 'app/modules/shell';

import 'app/partials/notification';

import messages from 'configs/messages';

import first from 'lodash/first';
import map from 'lodash/map';

import {
  productAddError,
  productAddSuccess,
  productsListEmpty,
  productUnavailable,
  productAddedAndUnavailable,
  urlFlagForLaunch,
  widgetAccessibilityClassName,
  widgetButtonClassName,
  widgetNotificationSelector,
  widgetNotificationWrapperSelector,
  widgetNotificationWrapperContainerSelector,
  widgetNotificationClassName,
  wrapperNotificationCartButtonClassName,
} from './config';

function getCartResponseStatus(responseJSON) {
  if (!responseJSON || !responseJSON.productsFailed || !responseJSON.productsUpdated) {
    return productAddError;
  }

  const failedLength = responseJSON.productsFailed.length;
  const successLength = responseJSON.productsUpdated.length;

  if (!failedLength && !successLength) {
    return productsListEmpty;
  }

  if (failedLength && !successLength) {
    return productUnavailable;
  }

  if (failedLength && successLength) {
    return productAddedAndUnavailable;
  }

  return productAddSuccess;
}

function getSCCurrentName() {
  const pdpMeta = document.querySelectorAll('meta[name=product-id]');
  const rangeMeta = document.querySelectorAll('meta[name=range-name]');

  if (pdpMeta.length) {
    return pdpMeta.attr('content');
  }

  if (rangeMeta.length) {
    return rangeMeta.attr('content');
  }

  return '';
}

let paramsWasLoaded = false;
let scriptWasLoaded = false;
let shouldLaunchByDefault = false;
let wasInit = false;

function initEmbedWidget(widget, publishMessage) {
  const { articleTitle, tplApi, tplScript, tplStyle, notificationCartBtnLabel, notificationCartLink } = widget.dataset;

  let intervalId = null;
  let widgetParams = null;
  let wrapperInstance = null;

  const configurationType = 'configuration';
  const productSelectorType = 'productSelector';

  function init() {
    if (wasInit) return;
    const scriptSC = document.createElement('script');
    const styleSC = document.createElement('link');

    scriptSC.src = tplScript;
    scriptSC.setAttribute('type', 'text/javascript');
    scriptSC.setAttribute('charset', 'utf-8');
    scriptSC.setAttribute('media', 'all');
    scriptSC.onload = () => {
      scriptWasLoaded = true;
    };

    styleSC.setAttribute('rel', 'stylesheet');
    styleSC.setAttribute('type', 'text/css');
    styleSC.setAttribute('href', tplStyle);

    document.body.appendChild(styleSC);
    document.body.appendChild(scriptSC);

    wasInit = true;

    wrapperInstance = null;
  }

  function getWidgetParams() {
    ajax
      .fetchJSON(tplApi)
      .then(
        (res) => {
          widgetParams = res;
          paramsWasLoaded = true;
        },
        () => {},
      );
  }

  function selectSCGroupID(configurationEvent) {
    switch (configurationEvent.type) {
      case configurationType:
        return configurationEvent.configurationId;
      default:
        return '';
    }
  }

  function formSCRequestData(configurationEvent) {
    const orderingId = configurationEvent.extras.orderingId || {};
    const codification = configurationEvent.extras.codification || {};
    const configurationName = configurationEvent.extras.configurationName || {};

    return {
      products: map(configurationEvent.products, (item) => ({
        productId: item.reference,
        quantity: item.quantity || 1,
      })),
      grouping: {
        enable: true,
        displayName: configurationName || getSCCurrentName(),
        configurationMasterId: selectSCGroupID(configurationEvent) || '',
        quantity: configurationEvent.quantity || 1,
        backURL: '',
        extras: {
          codification,
          orderingId,
        },
      },

      options: {
        allowPartialAdding: true,
        source: `SDL_$${configurationEvent.type}`,
      },
    };
  }

  function callbackHandler(responseJSON) {
    const status = getCartResponseStatus(responseJSON);
    let message = widgetParams.messageParams[`${status}`];
    const productsNotAdded = responseJSON.productsFailed.join(', ');

    if (status === productAddedAndUnavailable) {
      message = message.replace('{0}', productsNotAdded);
    }

    const notificationtWrapper = document.body.querySelector(widgetNotificationWrapperSelector);
    const notificationText = document.body.querySelector(widgetNotificationSelector);
    const subscriptionMessage = notificationText.innerHTML;

    const previousNotificationLinkElement = notificationtWrapper
      ?.querySelector(`.${wrapperNotificationCartButtonClassName}`);
    if (previousNotificationLinkElement) {
      previousNotificationLinkElement.remove();
      clearTimeout(window.notificationTimeOut);
    }

    const wrapperNotificationCartButtonElement = document.createElement('div');
    wrapperNotificationCartButtonElement.classList.add(wrapperNotificationCartButtonClassName);
    wrapperNotificationCartButtonElement.innerHTML = `
      <a
        href="${notificationCartLink}"
        type="button"
        class="notification-cart-btn se2-text-small">
        ${notificationCartBtnLabel}
      </a>
    `;

    notificationText.innerText = message;
    notificationtWrapper.classList.add(widgetNotificationClassName);

    document.body.querySelector(widgetNotificationWrapperContainerSelector).after(wrapperNotificationCartButtonElement);
    publishMessage(messages.cartUpdatedByProductSelector, { message });

    // eslint-disable-next-line no-return-assign
    window.notificationTimeOut = setTimeout(() => {
      notificationText.innerHTML = subscriptionMessage;
      notificationtWrapper.classList.remove(widgetNotificationClassName);
      wrapperNotificationCartButtonElement.remove();
    }, 5500);
  }

  function addProductHandlerFn(configurationEvent) {
    const productsData = formSCRequestData(configurationEvent);
    const body = JSON.stringify(productsData);
    const headers = new Headers([
      ['Accept', 'application/json'],
      ['Content-Type', 'application/json'],
    ]);

    const cartUrl = `${widgetParams.content.pesEndPoint}product/my-products`;

    ajax
      .fetchJSON(cartUrl, {
        headers,
        method: 'POST',
        body,
      })
      .then(
        (res) => {
          callbackHandler(res);
          analytics.trackAddToCartViaProductSelectorWidget({
            res,
            products: configurationEvent.products,
          });
        },
        () => {},
      );
  }

  function launchWidgetAfterScriptIsReady() {
    intervalId = setInterval(() => {
      if (scriptWasLoaded && paramsWasLoaded && window.seWidgetWrapper) {
        clearInterval(intervalId);
        intervalId = null;
      }

      if (wrapperInstance) {
        wrapperInstance.destroy();
      }

      if (window.seWidgetWrapper && widgetParams) {
        if (widgetParams.parameterParams.hierarchy) {
          wrapperInstance = window.seWidgetWrapper.startHierarchy(
            widgetParams.parameterParams.hierarchy,
            widgetParams.content.language,
            widgetParams.content.country,
            widgetParams.parameterParams.project,
            widgetParams.parameterParams.token,
            widgetParams.content.widgetOptions,
          );
        } else if (widgetParams.parameterParams.selectedProduct) {
          wrapperInstance = window.seWidgetWrapper.startWidget(
            widgetParams.parameterParams.selectedProduct,
            widgetParams.content.language,
            widgetParams.content.country,
            widgetParams.parameterParams.project,
            widgetParams.parameterParams.token,
            widgetParams.content.widgetOptions,
          );
        } else {
          wrapperInstance = window.seWidgetWrapper.startHierarchy(
            widgetParams.parameterParams.dataset,
            widgetParams.content.language,
            widgetParams.content.country,
            widgetParams.parameterParams.project,
            widgetParams.parameterParams.token,
            widgetParams.content.widgetOptions,
          );
        }
      }

      if (wrapperInstance) {
        wrapperInstance.addSaveConfigurationListener(addProductHandlerFn);
      }

      analytics.trackVirtualPageview({ articleTitle, pageSubSubCategoryKey: productSelectorType });
    }, 1000);
  }

  return {
    addProductHandlerFn,
    init,
    intervalId,
    getWidgetParams,
    launchWidgetAfterScriptIsReady,
    widget,
  };
}

export default shell.registerPartial($this, ({ addEventListener, mount, publishMessage, settings }) => {
  const launchWidget = async (widgetInstance, e) => {
    const widgetTitle = widgetInstance.widget.getAttribute('data-widget-title');
    const modal = (await import('app/partials/modal')).default;

    modal.showModal({
      shouldExpanded: true,
      appendHTML: `
        <div class="sc-widget-title">${widgetTitle}</div>
        <div id="widgetFrame" class="sc-widget-iframe"></div>
      `,
      labelCloseButton: widgetInstance.widget.dataset.videoCloseLabel,
      labelExpandButton: widgetInstance.widget.dataset.videoExpandLabel,
      target: shouldLaunchByDefault
        ? document.body.querySelector('a, button')
        : e.target.closest(`.${widgetButtonClassName}`),
    });

    widgetInstance.getWidgetParams();
    widgetInstance.init();
    widgetInstance.launchWidgetAfterScriptIsReady();
  };

  const deferLaunch = (widgetInstance) => {
    addEventListener(widgetInstance.widget, 'click', (e) => launchWidget(widgetInstance, e));
  };

  const params = new URL(document.location).searchParams;
  if (params.get(urlFlagForLaunch)) {
    shouldLaunchByDefault = true;
  }

  mount((element) => {
    const openModalButton = element.querySelector(`.${widgetButtonClassName}`);
    openModalButton.classList.toggle(widgetAccessibilityClassName, settings.accessibility);

    const widgetInstance = initEmbedWidget(openModalButton, publishMessage);

    shouldLaunchByDefault && first(document.querySelectorAll(`.${widgetButtonClassName}`)) === openModalButton
      ? launchWidget(widgetInstance)
      : deferLaunch(widgetInstance);
  });
});
