var $class="se2--content-flexible-blocks",$name="ContentFlexibleBlocks",$path="app/components/ContentFlexibleBlocks/config.js",$this={$class,$name,$path,};export const activeSlideClassName = 'active-slide';
export const slideClassName = 'slide';
export const slideDescriptionSelector = '.slide-description';
export const slideSelector = '.slide';
export const swiperContainerSelector = '.swiper-container';
export const contentEndClassName = 'content-end';
export const controlsSelector = '.controls';
export const nextButtonSelector = '.swiper-button-next';
export const prevButtonSelector = '.swiper-button-prev';
export const tipsHeaderSelector = '.tips-header';
export const tipsInfoSelector = '.tips-info';
export const tipsSelector = '.flexible-blocks-tips';
