var $class="se2--image",$name="image",$path="app/partials/image/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

export default shell.registerPartial($this, ({ mount, addEventListener }) => {
  mount((element) => {
    const image = element.querySelector('img');

    const onError = () => {
      image.setAttribute('data-img-is-loaded', false);
    };

    addEventListener(image, 'error', onError);
  });
});
