var $name="shell/registry/VisualContext",$path="app/modules/shell/registry/VisualContext.js",$this={$name,$path,};import Context from './Context';

export default class VisualContext extends Context {
  #parent;

  constructor(parent) {
    super(parent);
    this.#parent = parent;
  }

  /**
   * @returns { HTMLElement[] }
   */
  get elements() {
    return this.#parent.elements;
  }
}
