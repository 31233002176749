var $name="accessibility/index",$path="app/modules/accessibility/index.js",$this={$name,$path,};import shell from 'app/modules/shell';

import forEach from 'lodash/forEach';

export default shell.registerModule($this, ({
  configs, settings, subscribeToMessage,
}) => {
  const launchVideos = () => {
    forEach(
      document.querySelectorAll(`.${configs.css.classNames.accessibleVideo}`),
      (video) => video[settings.accessibility ? 'pause' : 'play'](),
    );
  };

  subscribeToMessage(configs.messages.accessibility, launchVideos);

  launchVideos();
});
