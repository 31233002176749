var $name="shell/index",$path="app/modules/shell/index.js",$this={$name,$path,};import configs from 'configs';

import analyze from './analyze';
import engine from './engine';
import Performance from './Performance';
import registry from './registry';
import settings from './settings';

const performance = new Performance($this);
performance.mark('start');

let namespace;
// eslint-disable-next-line no-prototype-builtins
if (window.hasOwnProperty(configs.namespaces.public)) {
  namespace = window[configs.namespaces.public];
} else {
  // eslint-disable-next-line no-multi-assign
  window[configs.namespaces.public] = namespace = {};
}

function dispose() {
  engine.dispose();
  settings.dispose();
  delete namespace.shell;
}

/**
 * @type { IShell }
 */
const shell = {
  dispose,
  ...registry,
};

engine.activate();
Object.defineProperties(namespace, {
  analyze: { value: analyze },
  settings: { value: settings },
});

performance.measure('activation', 'start');

export default shell;
