var $name="analytics/getConfig",$path="app/modules/analytics/getConfig.js",$this={$name,$path,};import Log from 'app/modules/shell/Log';
import { analytics } from 'configs';
import memoize from 'lodash/memoize';

const log = new Log($this);

function getConfig() {
  const { isConsumerPage } = window;
  let { sdlGoogleAnalyticConfig } = window;
  if (sdlGoogleAnalyticConfig == null) {
    sdlGoogleAnalyticConfig = {};
    log.warn('Google analytics configuration was not found, using default');
  }
  return { ...analytics, ...sdlGoogleAnalyticConfig, isConsumerPage };
}

export default memoize(getConfig);
