var $name="shell/engine/watchDOMMutations",$path="app/modules/shell/engine/watchDOMMutations.js",$this={$name,$path,};// This module is needed if we want to add components to the page dynamically.
// Like if the render tree was build, and after some time we want to add more components.
// In current state of the project this module is needed only for test run.
// The test has method render, which takes the component json and builds it in virtual browser.
import registry from '../registry';

export default function watchDOMMutations() {
  const observer = new MutationObserver(
    (mutations) => {
      const added = [];
      const removed = [];
      mutations.forEach(
        ({ addedNodes, removedNodes }) => {
          added.push(...addedNodes);
          removed.push(...removedNodes);
        },
      );
      registry.unmountElements(removed);
      registry.mountElements(added);
    },
  );

  observer.observe(document.body, {
    subtree: true,
    childList: true,
  });

  return () => observer.disconnect();
}
