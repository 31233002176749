var $class="se2--uberflip-card",$name="uberflipCard",$path="app/partials/uberflipCard/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

import isUsingKeyboard from 'app/utilities/isUsingKeyboard';
import parseDecimal from 'app/utilities/parseDecimal';

import fromPairs from 'lodash/fromPairs';
import map from 'lodash/map';

export const uberflipContainerSelector = '.uberflip-cards';
export const prevButtonSelector = '.swiper-button-prev';
export const nextButtonSelector = '.swiper-button-next';
export const slideSelector = '.slide';
export const swiperContainerSelector = '.swiper-container';
export const descriptionClassName = 'card-text';
export const contentClassName = 'animated-content-body';

const videoSelector = `.${$class} video`;

export const formatDate = (date, locale) => {
  // eslint-disable-next-line no-param-reassign
  locale = locale === 'null-NULL' ? 'en-ww' : locale;
  const { day, month, year } = fromPairs(
    map(
      new Intl.DateTimeFormat(locale, { year: '2-digit', month: 'short', day: '2-digit' })
        .formatToParts(new Date(date)),
      ({ type, value }) => [type, value],
    ),
  );
  return `${day} ${month}, ${year}`;
};

export default shell.registerComponent($this, ({
  addEventListener, mount, settings,
}) => {
  const adjustDescriptionHeight = (element) => {
    const descriptionContainerElement = element.querySelector(`.${descriptionClassName}`);
    if (!descriptionContainerElement) return;
    const cardTextMarginBottom = parseDecimal(window.getComputedStyle(descriptionContainerElement).marginBlockEnd);
    const targetContainerElement = element.querySelector(`.${contentClassName}`);
    targetContainerElement.style.insetBlockStart = `${descriptionContainerElement.getBoundingClientRect().height
      + cardTextMarginBottom}px`;
  };

  const onLinkClick = ({ code, target }) => {
    if (isUsingKeyboard()) {
      if (code === 'Enter') {
        if (target.classList.contains('slide') || target.classList.contains('slider-header')) {
          const link = target.querySelector('.button');
          link.click();
        }
      }
    }
  };

  const setRoleToSlide = (slide) => {
    const links = slide.querySelectorAll('.button');
    slide.setAttribute('role', links.length ? 'link' : 'none');
  };

  const convertToLocalDate = (slide) => {
    const cardDateElement = slide.querySelector('.date-container');
    if (cardDateElement) cardDateElement.innerText = formatDate(cardDateElement.dataset.date, settings.page.locale);
  };

  const onMouseEnter = ({ target }) => {
    !settings.accessibility && target.querySelector(videoSelector).play();
  };

  const onMouseLeave = ({ target }) => {
    target.querySelector(videoSelector).pause();
  };

  mount((element) => {
    setRoleToSlide(element);
    adjustDescriptionHeight(element);
    convertToLocalDate(element);
    addEventListener(element, 'keydown', onLinkClick);
    if (element.classList.contains('slide-video')) {
      addEventListener(element, 'mouseenter', onMouseEnter);
      addEventListener(element, 'mouseleave', onMouseLeave);
    }
  });
});
