var $name="setYoutubeVideoThumbnail",$path="app/utilities/setYoutubeVideoThumbnail.js",$this={$name,$path,};import { imageNaturalSizes } from 'configs';

import findIndex from 'lodash/findIndex';
import forEach from 'lodash/forEach';
import includes from 'lodash/includes';
import some from 'lodash/some';

import getVideoId from './getVideoId';

/**
 * Set thumbnail background for block with Youtube video.
 * @param {HTMLElement} element
 * @param {string} className
 */
export default function setYoutubeVideoThumbnail(element, className) {
  const embeddedPlayerElements = element.querySelectorAll(`.${className}`);
  if (!embeddedPlayerElements.length) return;

  forEach(embeddedPlayerElements, (node) => {
    const videoId = getVideoId(node);
    if (!videoId || some(node.children, { tagName: 'IMG' })) return;

    const image = new Image();
    image.src = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
    // TODO: Need to improve the alt attribute filling for accessibility
    image.alt = '&nbsp;';
    image.onload = ({ target }) => {
      const thumbnails = ['maxresdefault', 'sddefault', 'hqdefault', 'mqdefault', 'default'];
      const { src, naturalWidth, naturalHeight } = target;
      const { width, height } = imageNaturalSizes;

      if (naturalWidth === width && naturalHeight === height) {
        const thumbnailIndex = findIndex(thumbnails, (thumbnail) => includes(src, thumbnail));
        if (thumbnailIndex > -1) target.src = src.replace(thumbnails[thumbnailIndex], thumbnails[thumbnailIndex + 1]);
      }
    };
    node.appendChild(image);
  });
}
