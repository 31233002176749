var $name="analytics/index",$path="app/modules/analytics/index.js",$this={$name,$path,};import shell from 'app/modules/shell';
import forEach from 'lodash/forEach';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import getCountryLanguageCodes from 'app/utilities/getCountryLanguageCodes';

import { cartProductSelectorWidgetProvider } from './cart';
import { chatActionProvider } from './chat';
import { contactSubmitProvider } from './contact';
import { searchProvider } from './search';
import { downloadProvider } from './download';
import getConfig from './getConfig';
import getDataLayer from './getDataLayer';
import { exitToSchneiderProvider } from './exitToSchneider';
import { newsletterSubscriptionProvider } from './newsletter';
import { pageviewProvider } from './pageview';
import { ctaLinkProvider } from './ctaLink';
import { menuClickProvider } from './menuClick';

const events = {
  addToCart: 'addToCart',
  callSupportNow: 'callSupportNow',
  chatAction: 'chatAction',
  contactSubmit: 'contactSubmit',
  download: 'download',
  newsletterSubscription: 'newsletterSubscription',
  virtualPageview: 'virtualPageview',
  exitToSchneider: 'exit_to_schneider',
  cta: 'cta_click',
  search: 'search',
  menuClick: 'menu_click',
};

const domains = {
  apc: 'apc.com',
  se: 'se.com',
  seMainSite: 'SE Main site',
};

export default shell.registerModule($this, ({
  addEventListener, log, mount, settings,
}) => {
  const track = (provider) => Promise.race([
    new Promise(
      (eventCallback) => {
        if (settings.analytics) {
          const variables = provider(getConfig(), settings);
          log.info('Tracking:\n', variables);
          getDataLayer().push({ ...variables, eventCallback });
        } else {
          eventCallback();
        }
      },
    ),
    new Promise(
      (timeoutCallback) => setTimeout(timeoutCallback, 1000),
    ),
  ]);

  /**
   * @template T
   * @param { string } [event]
   * @param { (variables: T, config: object, settings: object ) => object } [provider]
   * @returns { (variables: T) => void }
   */
  function createTracker(event, provider) {
    const parameters = [event, provider];
    /* eslint-disable no-param-reassign */
    event = null;
    provider = null;
    for (const parameter of parameters) {
      if (isString(parameter)) {
        event = parameter;
      } else if (isFunction(parameter)) {
        provider = parameter;
      } else if (!isUndefined(parameter)) {
        log.warn('Unexpected type of parameter for tracker:', parameter);
      }
    }
    /* eslint-enable no-param-reassign */

    if (event != null) {
      return provider == null
        ? () => track(() => ({ event }))
        : (variables) => track((...args) => ({ event, ...provider(variables, ...args) }));
    }
    if (provider != null) {
      return (variables) => track((...args) => provider(variables, ...args));
    }
    log.warn('Insufficient number of parameters for tracker:\n', parameters);
    return () => {
      log.warn('Attempt to track a misconfigured event');
    };
  }

  const trackAddToCartViaProductSelectorWidget = createTracker(events.addToCart, cartProductSelectorWidgetProvider);
  const trackCallSupportNow = createTracker(events.callSupportNow);
  const trackChatAction = createTracker(events.chatAction, chatActionProvider);
  const trackContactSubmit = createTracker(events.contactSubmit, contactSubmitProvider);
  const trackSearch = createTracker(events.search, searchProvider);
  const trackDownload = createTracker(events.download, downloadProvider);
  const trackNewsletterSubscription = createTracker(events.newsletterSubscription, newsletterSubscriptionProvider);
  const trackVirtualPageview = createTracker(events.virtualPageview, pageviewProvider);
  const trackExitToSchneider = createTracker(events.exitToSchneider, exitToSchneiderProvider);
  const trackCTA = createTracker(events.cta, ctaLinkProvider);
  const trackMenuClick = createTracker(events.menuClick, menuClickProvider);

  function getValueFromTable(row, cellIndex) {
    if (!row || !row.cells || row.cells.length <= cellIndex) {
      return null;
    }
    const cell = row.cells[cellIndex];
    return cell?.textContent?.trim();
  }

  const onDownloadLinkClick = ({ target }) => {
    try {
      const documentURL = target?.href ?? target.closest('a')?.href;
      if (!documentURL) {
        return;
      }

      const urlObj = new URL(documentURL);
      const closestRow = target.closest('tr');
      const fileNameValue = getValueFromTable(closestRow, 1);
      const enDocTypeValue = getValueFromTable(closestRow, 2);

      if (!urlObj.searchParams.has('p_File_Name') || urlObj.hostname.includes('flipbook')) {
        if (fileNameValue) {
          urlObj.searchParams.set('p_File_Name', fileNameValue); // set value from table
        }
      }

      if ((urlObj.searchParams.has('p_enDocType') && urlObj.searchParams.get('p_enDocType') === 'EDMS')
        || urlObj.hostname.includes('flipbook')) {
        if (enDocTypeValue) {
          urlObj.searchParams.set('p_enDocType', enDocTypeValue); // set value from table
        }
      }

      trackDownload({
        documentURL: urlObj.href,
      });
    } catch (error) {
      /* eslint-disable no-console */
      console.error('Error in onDownloadLinkClick:', error);
    }
  };

  const onExitToSchneiderClick = ({ target }) => trackExitToSchneider({
    linkUrl: target?.href ?? target.closest('a')?.href,
  });

  function getValueFromConfig(config, ctaClick) {
    const templatePatterns = Object.keys(config);
    const url = new URL(ctaClick);
    const { countryCode, languageCode } = getCountryLanguageCodes(ctaClick);
    const query = url.pathname;
    const queryPathname = query.replace(`/${countryCode}/${languageCode}`, '');
    const queryHost = url.hostname;
    let result = '';
    let length = 0;
    templatePatterns.forEach((rule) => {
      let match;
      if (rule.includes('*')) {
        if (rule.includes(queryHost)) {
          const regexp = new RegExp(`^${rule}`);
          match = regexp.test(queryHost);
        } else {
          const regexp = new RegExp(`^${rule}`);
          match = regexp.test(queryPathname);
        }
      } else if (rule.includes(queryHost)) {
        match = queryHost === rule;
      } else {
        match = queryPathname === rule;
      }
      if (match && match !== -1 && length < rule.length) {
        result = config[`${rule}`];
        length = rule.length;
      }
    });
    return result;
  }

  const onCTAClick = ({ target }) => trackCTA({
    cta_link: target?.href ?? (target.closest('a')?.href || target.closest('button')?.dataset?.href),
    cta_name: target?.dataset?.name ?? (target.closest('a')?.dataset?.name || target.closest('button')?.dataset?.name),
    cta_location: target?.dataset?.tmp ?? (target.closest('a')?.tmp || target.closest('button')?.tmp),
    cta_category: getValueFromConfig(window.sdlGoogleAnalyticConfig.mappings,
      target?.href ?? (target.closest('a')?.href || target.closest('button')?.dataset?.href)) || '',
  });
  // eslint-disable-next-line max-len
  const selectDownloadLinks = (element) => element.querySelectorAll('a[href*="p_Doc_Ref"]');
  const selectCallSupportLinks = (element) => element.querySelectorAll(
    `a[href^="${getConfig().callSupportNowMarker}"]`,
  );
  const selectExitToSchneiderLinks = (element) => element.querySelectorAll(
    `ul[class*="brand-list"] li a, a[href*="${domains.se}"], a[href*="${domains.apc}"]`,
  );
  // TODO: remove classic related classes when all platform is moved to redesign.
  // Classic repo classes are added as, PES has redesign CAAS header and classic components.
  const selectCTALinks = (element) => element.querySelectorAll(
    `a.se2--single-link,
    a.se2--link-cta,
    a.stretched-link, 
    .se2--link-list a,
    button.se2--link-cta,
    [type='button'][data-href]:not([data-href='']),
    [class*='se-btn-primary'][data-href]:not([data-href='']),
    [class*='se-btn-primary'][href]:not([href='']),
    [class*='se-btn-secondary'][data-href]:not([data-href='']),
    [class*='se-btn-secondary'][href]:not([href='']),
    a[class^='se-font-a11y-link'][href]:not([href='']),
    a[class^='se-font-static-a11y-link'][href]:not([href='']),
    .sdl-container-teaser_item-link a[href]:not([href=''])`,
  );
  const setUpOnDownloadLinkClickTracker = (element) => {
    if (settings.analytics && getConfig().triggerDownload && !getConfig().isConsumerPage) {
      forEach(
        selectDownloadLinks(element),
        (link) => {
          addEventListener(link, 'click', onDownloadLinkClick);
        },
      );
    }
  };

  const setUptrackCallSupportNowTracker = (element) => {
    if (settings.analytics && !getConfig().isConsumerPage) {
      forEach(
        selectCallSupportLinks(element),
        (link) => {
          addEventListener(link, 'click', trackCallSupportNow);
        },
      );
    }
  };

  const setUpOnExitToSchneiderClickTracker = (element) => {
    const { digitalPlatform, isConsumerPage, exitToSchneider } = getConfig();
    const isDigitalPlatform = digitalPlatform === domains.apc || digitalPlatform === domains.seMainSite;
    if (settings.analytics && !isConsumerPage && isDigitalPlatform && exitToSchneider) {
      forEach(
        selectExitToSchneiderLinks(element),
        (link) => {
          addEventListener(link, 'click', onExitToSchneiderClick);
        },
      );
    }
  };

  const setUpCTAClickTracker = (element) => {
    if (settings.analytics && !getConfig().isConsumerPage && getConfig().ctaClick) {
      forEach(Array.from(selectCTALinks(element))
        .filter((el) => !el.closest('nav'))
        .filter((el) => !el.closest('header'))
        .filter((el) => !el.closest('footer')),
      (link) => {
        addEventListener(link, 'click', onCTAClick);
      });
    }
  };

  /* eslint-disable camelcase */
  const menuClickEvent = (menuLevels) => trackMenuClick({
    menu_type: menuLevels.menuType,
    menu_category_1: menuLevels.menuLevel1,
    menu_category_2: menuLevels.menuLevel2,
    menu_category_3: menuLevels.menuLevel3,
    menu_category_4: menuLevels.menuLevel4,
    menu_category_5: menuLevels.menuLevel5,
  });
  /* eslint-disable camelcase */

  mount((element) => {
    setUpOnDownloadLinkClickTracker(element);
    setUptrackCallSupportNowTracker(element);
    setUpOnExitToSchneiderClickTracker(element);
    setUpCTAClickTracker(element);
  });

  // TODO: activate GA script

  return {
    events,
    trackAddToCartViaProductSelectorWidget,
    trackCallSupportNow,
    trackChatAction,
    trackContactSubmit,
    trackDownload,
    trackExitToSchneider,
    trackNewsletterSubscription,
    trackVirtualPageview,
    trackSearch,
    setUpOnDownloadLinkClickTracker,
    setUptrackCallSupportNowTracker,
    setUpOnExitToSchneiderClickTracker,
    setUpCTAClickTracker,
    menuClickEvent,
  };
});
