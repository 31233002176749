var $name="isMountable",$path="app/utilities/isMountable.js",$this={$name,$path,};import some from 'lodash/some';

const notMountable = [
  HTMLBaseElement,
  HTMLHeadElement,
  HTMLHtmlElement,
  HTMLLinkElement,
  HTMLMetaElement,
  HTMLScriptElement,
  HTMLStyleElement,
  HTMLTemplateElement,
  HTMLTitleElement,
  HTMLUnknownElement,
];

/**
 * @param { HTMLElement } element
 * @returns { boolean }
 */
const isMountable = (element) => element instanceof HTMLElement
  && !some(
    notMountable,
    (NotMountable) => element instanceof NotMountable,
  );

export default isMountable;
