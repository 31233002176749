var $class="se2--snackbar-component",$name="snackbarComponent",$path="app/partials/snackbarComponent/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

export default shell.registerComponent($this, ({
  mount, unmount, addEventListener,
}) => {
  let observer = null;

  const onSnackbarVisible = (element, callback) => {
    const className = 'invisible';

    observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'attributes'
          && mutation.attributeName === 'class'
          && !element.classList.contains(className)) {
          callback();
        }
      }
    });

    observer.observe(element, { attributes: true });
  };

  const handleCloseBtnClick = ({ target }) => {
    target.closest('.snackbar-container').classList.add('invisible');
  };

  const hideSnackbarAfter5Seconds = (element) => {
    if (!element || element.classList.contains('invisible')) {
      return;
    }

    setTimeout(() => {
      // note: do not call observer.disconnect() here! We need to keep observing for
      // the case that during runtime somebody again shows the snackbar.

      element.classList.add('invisible');
    }, 5000);
  };

  mount((element) => {
    const closeBtn = element.querySelector('.close-btn');
    if (closeBtn) addEventListener(closeBtn, 'click', handleCloseBtnClick);

    onSnackbarVisible(element, () => {
      hideSnackbarAfter5Seconds(element);
    });
  });

  unmount(() => {
    if (observer != null) {
      observer.disconnect();
      observer = null;
    }
  });
});
