var $class="se2--form-component-wrapper",$name="formComponentWrapper",$path="app/partials/formComponentWrapper/index.js",$this={$class,$name,$path,};import ScrollTrigger from 'gsap/ScrollTrigger';

import ajax from 'app/modules/ajax';
import shell from 'app/modules/shell';

import getCookie from 'app/utilities/getCookie';
import prefillRadioButtonFromMarketo from 'app/components/utilities/prefillRadioButtonFromMarketo';
import prefillSelectFromMarketo from 'app/components/utilities/prefillSelectFromMarketo';
import 'app/partials/image';
import once from 'lodash/once';
import 'app/partials/snackbarComponent';
import 'app/partials/thankYouPage';

import { breakpoints, messages } from 'configs';
import { chartInfoBlockClassName } from '../../components/ContentLookup/partials/modalLookup/config';

const formHandlerConfigTcmUri = 'formHandlerConfigTcmUri';
const mktoCookie = '_mkto_trk';

const revealAnimation = (element) => {
  const backgroundImageElement = element.querySelector('.fixed-background');
  return ScrollTrigger.create({
    trigger: backgroundImageElement,
    start: 'bottom bottom',
    end: () => `+=${element.getBoundingClientRect().height - backgroundImageElement.getBoundingClientRect().height}px`,
    pin: true,
  });
};

// eslint-disable-next-line consistent-return
const revealTextAnimation = (element) => {
  const textWrapperElement = element.querySelector('.form-text-wrapper');
  const formWrapperElement = element.querySelector('.form-wrapper');
  const headerHeightDesktop = parseInt(getComputedStyle(document.documentElement)
    .getPropertyValue('--full-header-height-desktop'), 10);
  const headerHeightMobile = parseInt(getComputedStyle(document.documentElement)
    .getPropertyValue('--header-height-mobile'), 10);
  const headerHeight = window.innerWidth > breakpoints.xs.max ? headerHeightDesktop : headerHeightMobile;
  if (textWrapperElement != null) {
    return ScrollTrigger.create({
      trigger: textWrapperElement,
      start: () => `top +=${textWrapperElement.getBoundingClientRect().top
      - element.getBoundingClientRect().top + headerHeight}px`,
      end: () => `+=${formWrapperElement.getBoundingClientRect().height}px`,
      pin: true,
    });
  }
};

const prefillFromMarketo = (form) => {
  const tcm = form[formHandlerConfigTcmUri];

  if (getCookie(mktoCookie) && tcm && tcm.value) {
    const { mktoUrl } = form.dataset;

    if (mktoUrl) {
      ajax.fetchJSON(`${mktoUrl}?${formHandlerConfigTcmUri}=${tcm.value}`).then(
        (data) => {
          Object.keys(data).forEach((name) => {
            const field = form[name];

            if (field) {
              if (field.tagName) {
              // check if field is a select
                if (field.length) {
                  prefillSelectFromMarketo(field, data[name]);
                // check if field is a checkbox
                } else if (field.type === 'checkbox') {
                  field.checked = true;
                // fields are input and textarea
                } else {
                  field.value = data[name];
                }
              // check if field is a radio(list of elements with mutual name)
              } else if (field instanceof HTMLCollection || field instanceof NodeList) {
                prefillRadioButtonFromMarketo(field, data[name]);
              }
            }
          });
        },
        () => {},
      );
    }
  }
};

export default shell.registerPartial($this, ({ mount, subscribeToMessage, unmount }) => {
  let form = null;
  const gsapAnimation = new Map();

  const initAnimation = (element) => {
    const { contentId } = element.dataset;

    if (gsapAnimation.has(contentId)) {
      gsapAnimation.get(contentId).forEach((animation) => {
        animation.refresh();
      });
    } else {
      const scrollTrigger = revealAnimation(element);
      const textScrollTrigger = revealTextAnimation(element);
      gsapAnimation.set(contentId, [scrollTrigger, textScrollTrigger]);
    }
  };

  const bodyResizeObserver = new ResizeObserver(() => {
    gsapAnimation.forEach((animations) => animations.forEach((animation) => { animation.refresh(); }));
  });

  const backgroundResizeObserver = new ResizeObserver((entries) => {
    entries.forEach((e) => {
      window.requestAnimationFrame(() => initAnimation(e.target));
    });
  });

  const intersectionObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) return;
      window.requestAnimationFrame(() => initAnimation(entry.target));

      backgroundResizeObserver.observe(entry.target);

      observer.unobserve(entry.target);
    });
  });

  const refreshAnimation = () => {
    gsapAnimation.forEach(([scrollTrigger, textScrollTrigger]) => {
      scrollTrigger.refresh();
      textScrollTrigger.refresh();
    });
  };

  const warrantyLookupTooltip = (element) => {
    const tooltipModalTogglerElement = element.querySelector('.tooltip-lookup');
    const infoDescription = element.querySelector('.description-lookup-tooltip');
    const closeButtonClassName = 'close-btn';

    const importModal = once(
      async () => (await import('../../components/ContentLookup/partials/modalLookup/')).default,
    );

    const closeModal = async () => {
      const modal = await importModal();
      if (modal.wrapElement.classList?.contains(chartInfoBlockClassName)) {
        modal.hideModal();
      }
    };

    const openTooltipModal = async () => {
      let modalElement = null;
      const modal = await importModal();
      modalElement = modal.showModal({
        isCharts: true,
        appendHTML: `${infoDescription?.cloneNode(true).innerHTML}`,
      });
      modalElement.querySelector(`.${closeButtonClassName}`)?.addEventListener('click', () => {
        closeModal();
      });
    };

    tooltipModalTogglerElement?.addEventListener('click', () => {
      openTooltipModal();
    });
  };

  mount((element) => {
    const textWrapperElement = element.querySelector('.form-text-wrapper');

    form = element.querySelector('form');
    const pageUrl = form.querySelector('input[name="pageUrl"]');
    const referringUrl = form.querySelector('input[name="referringUrl"]');
    prefillFromMarketo(form);
    if (textWrapperElement != null) {
      intersectionObserver.observe(element);
      bodyResizeObserver.observe(document.body);
    }
    subscribeToMessage(messages.headerMount, () => window.requestAnimationFrame(refreshAnimation));
    if (pageUrl) pageUrl.value = window.location.href;
    if (referringUrl) referringUrl.value = document.referrer;

    warrantyLookupTooltip(element);
  });

  unmount(() => {
    gsapAnimation.forEach((animation) => animation.kill());
    backgroundResizeObserver.disconnect();
    bodyResizeObserver.disconnect();
  });
});
