var $class="se2--product-selector",$name="productSelector",$path="app/partials/productSelector/config.js",$this={$class,$name,$path,};export const productAddError = 'GL_PRODUCT_ADD_ERROR';
export const productAddSuccess = 'GL_PRODUCT_ADD_SUCCESS';
export const productsListEmpty = 'GL_PRODUCT_LIST_EMPTY';
export const productUnavailable = 'GL_PRODUCTS_UNAVAILABLE';
export const productAddedAndUnavailable = 'GL_PRODUCTS_ADDED_AND_UNAVAILABLE';
export const urlFlagForLaunch = 'loadProductSelector';
export const widgetAccessibilityClassName = 'embed-widget-with-accessibility';
export const widgetButtonClassName = 'open-embed-widget';
export const widgetTitleClassName = 'sc-widget-title';
export const widgetNotificationSelector = '.notification-container .notification';
export const widgetNotificationWrapperSelector = '.notification-container .notification-wrapper';
export const widgetNotificationWrapperContainerSelector = '.notification-container .notification-wrap';
export const widgetNotificationClassName = 'widget-notification-message';
export const wrapperNotificationCartButtonClassName = 'wrapper-notification-cart-btn';
