var $name="analytics/cart",$path="app/modules/analytics/cart.js",$this={$name,$path,};import forEach from 'lodash/forEach';
import map from 'lodash/map';

/**
 * @param { {
 *  res: {
 *    groupId: string,
 *    infoForGa: {
 *      productId: {
 *        brand: string,
 *        description: string,
 *      },
 *    }
 *    productsFailed: Array<string>  // Array of productIds
 *    productsUpdated: Array<string> // Array of productIds
 *    status: string
 *  },
 *  products: {
 *    dataSheetUrl: string,
 *    description: string,
 *    isCore: boolean,
 *    pictureUrl: string,
 *    productId: string,
 *    quantity: number
 *    reference: string,
 * }[]
 * } } variables
 */

const cartProductSelectorWidgetProvider = ({ res, products }, config) => {
  const productsQuantity = {};
  forEach(
    products,
    ({ productId, quantity }) => {
      productsQuantity[productId] = quantity;
    },
  );

  return {
    event: 'addToCart',
    addToCartLocation:
      res.productsUpdated.length > 1
        ? config.addToCartLocation?.productSelectorGroup ?? 'product-selector|button:group-of-products'
        : config.addToCartLocation?.productSelectorSingle ?? 'product-selector|button:single-product',
    currencyCode: '', // TODO: define
    distributor: '', // TODO: define
    products: map(
      res.productsUpdated,
      (product) => ({
        brand: res.infoForGa[product].brand,
        category: '', // TODO: define
        id: product,
        name: res.infoForGa[product].description,
        price: '', // TODO: define
        quantity: productsQuantity[product],
        variant: '', // TODO: define
      }),
    ),
  };
};

// eslint-disable-next-line import/prefer-default-export
export { cartProductSelectorWidgetProvider };
