var $name="shell/registry/index",$path="app/modules/shell/registry/index.js",$this={$name,$path,};import isMountable from 'app/utilities/isMountable';

import castArray from 'lodash/castArray';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import isString from 'lodash/isString';

import ComponentUnit from './ComponentUnit';
import ModuleUnit from './ModuleUnit';
import PartialUnit from './PartialUnit';
import schedule from '../schedule';

/** @type { Map<string, VisualUnit> } */
const classes = new Map();
/** @type { Map<string, LogicalUnit> } */
const components = new Map();
/** @type { Map<HTMLElement, VisualUnit> } */
const elements = new Map();
/** @type { Map<string, LogicalUnit> } */
const modules = new Map();
/** @type { Map<string, VisualUnit> } */
const partials = new Map();

const isUnit = (source) => isString(source?.$path);
const isModule = (source) => isUnit(source) && !isString(source?.$class);
// TODO: introduce $type metavariable to distinguish components and partials
const isComponent = (source) => isUnit(source) && isString(source?.$class);
const isPartial = (source) => isUnit(source) && isString(source?.$class);

function validateUnit(source, type, validator) {
  if (!validator(source)) {
    throw new Error(
      `Unable to register a unit with the following "$this" as a ${type}: ${JSON.stringify(source)}`,
    );
  }
}

function dispose() {
  const units = [
    ...components.values(),
    ...partials.values(),
    ...modules.values(),
  ];
  forEach(
    [classes, elements, components, partials, modules],
    (collection) => collection.clear(),
  );
  forEach(
    units,
    (unit) => unit.dispose(),
  );
}

function isRegistered(source) {
  const { $class = source, $path = source } = source;
  return classes.has($class)
    || components.has($path)
    || modules.has($path)
    || partials.has($path);
}

function mountToLogicalUnits(element) {
  forEach(
    [...modules.values()],
    (unit) => unit.mount(element),
  );
}

function mountToVisualUnits(element) {
  forEach(element.children, mountToVisualUnits);
  if (!elements.has(element)) {
    const className = find(
      element.classList,
      (candidate) => classes.has(candidate),
    );
    if (className) {
      const unit = classes.get(className);
      elements.set(element, unit);
      unit.mount(element);
    }
  }
}

function mountElements(candidates) {
  forEach(
    castArray(candidates),
    (element) => {
      if (isMountable(element)) {
        mountToLogicalUnits(element);
        mountToVisualUnits(element);
      }
    },
  );
}

function unmountFromLogicalUnits(element) {
  forEach(
    [...modules.values()],
    (logical) => logical.unmount(element),
  );
}

function unmountFromVisualUnits(element) {
  forEach(
    [...element.children],
    unmountFromVisualUnits,
  );
  const unit = elements.get(element);
  if (unit) {
    elements.delete(element);
    unit.unmount(element);
  }
}

function unmountElements(candidates) {
  forEach(
    castArray(candidates),
    (element) => {
      if (isMountable(element)) {
        unmountFromVisualUnits(element);
        unmountFromLogicalUnits(element);
      }
    },
  );
}

function registerUnit(source, callback, collection, Unit, initialize) {
  const { $path } = source;
  let unit;
  if (collection.has($path)) {
    unit = collection.get($path);
  } else {
    unit = new Unit(source, callback);
    collection.set($path, unit);
    unit.activate();
    initialize(unit);
  }
  return unit.exports;
}

function registerModule(source, callback) {
  validateUnit(source, 'module', isModule);
  return registerUnit(source, callback, modules, ModuleUnit, (unit) => {
    schedule.interactive(
      () => unit.mount(document.body),
    );
  });
}

function registerVisualUnit(source, callback, collection, Unit) {
  return registerUnit(source, callback, collection, Unit, (unit) => {
    const { $class } = source;
    classes.set($class, unit);
    schedule.interactive(
      () => forEach(
        document.getElementsByClassName($class),
        (element) => {
          elements.set(element, unit);
          unit.mount(element);
        },
      ),
    );
  });
}

function registerComponent(source, callback) {
  validateUnit(source, 'component', isComponent);
  return registerVisualUnit(source, callback, components, ComponentUnit);
}

function registerPartial(source, callback) {
  validateUnit(source, 'partial', isPartial);
  return registerVisualUnit(source, callback, partials, PartialUnit);
}

const registry = {
  dispose,
  isRegistered,
  mountElements,
  registerComponent,
  registerModule,
  registerPartial,
  unmountElements,
};

export default registry;
