var $name="validationPatterns",$path="configs/validationPatterns.js",$this={$name,$path,};module.exports = {
  // eslint-disable-next-line
  commonEmailVsTelPattern: "(^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z0-9]{2,15}$|^[0-9\\-+ ()\\s]{1,}$)",
  // eslint-disable-next-line
  emailVsTelPattern: /^([a-zA-Z0-9._%+-])+\@(([a-zA-Z0-9.-])+\.)+([a-zA-Z0-9]{2,15})|(^[0-9-+()]{1,})+$/,
  // eslint-disable-next-line
  emailPattern: /^([a-zA-Z0-9._%+-])+\@(([a-zA-Z0-9.-])+\.)+([a-zA-Z0-9]{2,15})+$/,
  // eslint-disable-next-line
  telPattern: "^[0-9\\-+ ()\\s]{1,}$",
  // eslint-disable-next-line
  telMinPattern: "^[0-9\\-+ ()\\s]{10,}$",
  // eslint-disable-next-line
  telPatternNonMandatory: "^[0-9\\-+ ()\\s]{0,}$",
};
