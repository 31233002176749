var $class="se2--utilities",$name="utilities",$path="app/components/utilities/prefillRadioButtonFromMarketo.js",$this={$class,$name,$path,};import forEach from 'lodash/forEach';

function prefillRadioButtonFromMarketo(field, value) {
  forEach(field, (input) => {
    if (input.value === value && input.dataset.prefill === 'true') {
      input.checked = true;
    }
  });
}

export default prefillRadioButtonFromMarketo;
