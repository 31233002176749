var $name="shell/registry/Context",$path="app/modules/shell/registry/Context.js",$this={$name,$path,};import { mountEvent, unmountEvent } from './events';
import messaging from '../messaging';

export default class Context {
  #parent

  /**
   * @param { import('./Unit').default } parent
   */
  constructor(parent) {
    this.#parent = parent;
    Object.defineProperties(this, {
      log: { value: parent.log },
      performance: { value: parent.performance },
    });
  }

  get addEventListener() {
    return this.#parent.makeDisposable(
      (target, type, listener, options) => target?.addEventListener(type, listener, options),
      (target, type, listener, options) => target?.removeEventListener(type, listener, options),
    );
  }

  get dispose() {
    return (callback) => {
      this.#parent.addDisposer(callback);
    };
  }

  get mount() {
    return (callback) => {
      this.#parent.assignCallback(mountEvent, callback);
    };
  }

  // eslint-disable-next-line class-methods-use-this
  get publishMessage() {
    return messaging.publishMessage;
  }

  get subscribeToMessage() {
    return this.#parent.makeDisposable(
      messaging.subscribeToMessage,
      messaging.unsubscribeFromMessage,
    );
  }

  get unmount() {
    return (callback) => {
      this.#parent.assignCallback(unmountEvent, callback);
    };
  }
}
