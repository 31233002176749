var $class="se2--thank-you-page",$name="thankYouPage",$path="app/partials/thankYouPage/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

export default shell.registerComponent($this, ({
  mount,
}) => {
  const hideThankYouNotification = () => {
    // element?.classList.add(configs.css.classNames.hide);
  };

  mount((element) => {
    hideThankYouNotification(element);
  });
});
