var $name="shell/schedule",$path="app/modules/shell/schedule.js",$this={$name,$path,};const readyStates = {
  interactive: 1,
  complete: 2,
};

const createReadyStatePromise = (targetReadyState) => new Promise(
  (resolve) => {
    const onReadyStateChange = () => {
      if (readyStates[document.readyState] >= targetReadyState) {
        document.removeEventListener('readystatechange', onReadyStateChange);
        resolve();
      } else {
        document.addEventListener('readystatechange', onReadyStateChange);
      }
    };
    onReadyStateChange();
  },
);

const createScheduler = (promise) => (callback, delay = 0) => setTimeout(
  () => promise.then(callback),
  delay,
);

const interactive = createScheduler(createReadyStatePromise(readyStates.interactive));
const complete = createScheduler(createReadyStatePromise(readyStates.complete));

/**
 * @type { ISchedule }
 */
const schedule = { interactive, complete };

export default schedule;
