var $name="shell/messaging",$path="app/modules/shell/messaging.js",$this={$name,$path,};import allowOrigin from 'app/utilities/allowOrigin';
import isMessage from 'app/utilities/isMessage';

import { messages } from 'configs';

import forEach from 'lodash/forEach';
import isFunction from 'lodash/isFunction';
import isObjectLike from 'lodash/isObjectLike';

import Log from './Log';

const log = new Log($this);
const subscribers = new Map();

function onMessage({ data, origin }) {
  if (!allowOrigin(origin) || !isObjectLike(data)) return;
  const { message, payload } = data;
  // eslint-disable-next-line no-prototype-builtins
  if (!isMessage(message)) return;
  log.info(
    'Received:\n',
    { message, payload },
  );
  forEach(
    [...subscribers.get(message) ?? []],
    (subscriber) => {
      try {
        subscriber(payload, message);
      } catch (error) {
        log.error('Unable to execute subscriber:', error);
      }
    },
  );
}
window.addEventListener('message', onMessage, false);

function publishMessage(message, payload) {
  log.info(
    'Publishing:\n',
    { message, payload },
  );
  window.postMessage({ message, payload }, window.location.origin);
}

function subscribeToMessage(message, subscriber) {
  // eslint-disable-next-line no-prototype-builtins
  if (!messages.hasOwnProperty(message)) {
    log.error(`Message "${message}" is not defined`);
  } else if (!isFunction(subscriber)) {
    log.error('Subscriber must be a function');
  } else if (subscribers.has(message)) {
    const set = subscribers.get(message);
    if (set.has(subscriber)) {
      log.warn(
        'Subscriber has already been subscribed to the message:\n',
        { message, subscriber },
      );
    } else {
      set.add(subscriber);
    }
  } else {
    subscribers.set(message, new Set([subscriber]));
  }
}

function unsubscribeFromMessage(message, subscriber) {
  const set = subscribers.get(message);
  if (set?.has(subscriber)) {
    set.delete(subscriber);
  } else {
    log.warn(
      'Subscriber was not subscribed to the message:\n',
      { message, subscriber },
    );
  }
}

// Subscribe to the "ping" message so to respond to it with the "pong" message
subscribeToMessage(messages.ping, () => {
  publishMessage(messages.pong, Date.now());
});

/**
 * @type { IMessaging }
 */
const messaging = { publishMessage, subscribeToMessage, unsubscribeFromMessage };

export default messaging;
