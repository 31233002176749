var $name="shell/engine/watchAccessibility",$path="app/modules/shell/engine/watchAccessibility.js",$this={$name,$path,};import { css, messages } from 'configs';

import messaging from '../messaging';
import settings from '../settings';

function onAccessibility(accessibility) {
  document.documentElement.classList.toggle(css.classNames.accessibility, accessibility);
}

export default function watchAccessibility() {
  messaging.subscribeToMessage(messages.accessibility, onAccessibility);
  messaging.publishMessage(messages.accessibility, settings.accessibility);
}
