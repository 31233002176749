var $class="se2--header-se",$name="HeaderSE",$path="app/components/HeaderSE/utilities/isHeaderVersion8.js",$this={$class,$name,$path,};import { headerSelector, headerVersion8ClassName } from '../config';

const isHeaderVersion8 = () => {
  const headerElement = document.querySelector(headerSelector);
  return headerElement?.classList.contains(headerVersion8ClassName);
};

export {
  // eslint-disable-next-line import/prefer-default-export
  isHeaderVersion8,
};
