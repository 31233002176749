var $name="setTabIndex",$path="app/utilities/setTabIndex.js",$this={$name,$path,};/**
 * Enables or disables element's participation in tabbing order.
 * @param {HTMLElement} element
 * @param {boolean} participate
 */

function setTabIndex(element, participate) {
  element?.setAttribute('tabIndex', participate ? '0' : '-1');
}

export default setTabIndex;
