var $class="se2--utilities",$name="utilities",$path="app/components/utilities/lazyHandleOpenModalButtons.js",$this={$class,$name,$path,};import { css, delays } from 'configs';

import forEach from 'lodash/forEach';
import some from 'lodash/some';

/**
 * Initializes video module after first click to a play button
 * @param {Function} addEventListener
 * @param {HTMLElement} element
 * @param {string} className
 */

async function lazyHandleOpenModalButtons(addEventListener, element, classNames = [css.classNames.videoPlayButton]) {
  let videoButtonSelector = '';
  classNames.forEach((className, index, array) => {
    videoButtonSelector += `.${className}${index === array.length - 1 ? '' : ', '}`;
  });
  const hasThumbnailsChineseVideo = some(
    element.querySelectorAll(`.${css.classNames.videoThumbnail}`),
    (el) => (/bokecc|youku/i).test(el.dataset.videolink),
  );

  if (hasThumbnailsChineseVideo) {
    (await import('app/modules/video')).default.handleOpenModalButtons(element, videoButtonSelector);
  } else {
    const videoButtons = element.querySelectorAll(videoButtonSelector);
    let video = null;

    forEach(videoButtons, (button) => addEventListener(button, 'click', async (event) => {
      const clickedElement = event.target.closest(videoButtonSelector);
      if (clickedElement) {
        event.preventDefault();

        if (!video) video = (await import('app/modules/video')).default;
        video.handleOpenModalButtons(element, videoButtonSelector);

        const intervalId = setInterval(() => {
          if (video) {
            clickedElement.click();
            clearInterval(intervalId);
          }
        }, delays.s1);
      }
    },
    { once: true }));
  }
}

export default lazyHandleOpenModalButtons;
