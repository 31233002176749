var $name="analytics/ctaLink",$path="app/modules/analytics/ctaLink.js",$this={$name,$path,};/* eslint-disable camelcase */
import getEnvironment from './getEnvironment';

export const templateNames = {
  bannerFullImageCarousel: 'BannerHeroImageCarousel',
  bannerHeroImageCarousel: 'BannerFullImageCarousel',
  bannerGamingExperienceCarousel: 'BannerGamingExperienceCarousel',
  bannerAndInsertedSmallImage: 'BannerAndInsertedSmallImage',
  contentFlexibleBlocks: 'ContentFlexibleBlocks',
  contentFlexibleBlocksGrid: 'ContentFlexibleBlocksGrid',
  mixAndMatchContainer: 'MixAndMatchContainer',
  contentArticleSlideCarousel: 'ContentArticleSlideCarousel',
};

const selectClassName = (templateName) => {
  switch (templateName) {
    case templateNames.bannerFullImageCarousel:
    case templateNames.bannerGamingExperienceCarousel:
    case templateNames.bannerAndInsertedSmallImage:
    case templateNames.bannerHeroImageCarousel:
      return 'Banner & Info block container';
    case templateNames.contentFlexibleBlocks:
      return 'Slide blocks';
    case templateNames.MixAndMatchContainer:
    case templateNames.ContentArticleSlideCarousel:
      return 'Article blocks';
    default:
      return 'Miscellaneous blocks';
  }
};

/**
 * @param { { cta_link: string}  } variables
 * @param { { cta_name: string}  } variables
 * @param { { cta_location: string}  } variables
 * @param { { cta_category: string}  } variables
 */
function ctaLinkProvider({
  cta_link = '',
  cta_name = '',
  cta_location = '',
  cta_category = '',
} = {}, config) {
  return {
    cta_link,
    cta_name,
    cta_location: selectClassName(cta_location) ?? cta_location,
    cta_category,
    digitalPlatform: config.digitalPlatform,
    environment: getEnvironment(config),
  };
}

// eslint-disable-next-line import/prefer-default-export
export { ctaLinkProvider };
