var $name="keyNames",$path="configs/keyNames.js",$this={$name,$path,};module.exports = {
  arrowDown: 'ArrowDown',
  arrowLeft: 'ArrowLeft',
  arrowRight: 'ArrowRight',
  arrowUp: 'ArrowUp',
  end: 'End',
  enter: 'Enter',
  escape: 'Escape',
  home: 'Home',
  numpadEnter: 'NumpadEnter',
  space: 'Space',
  tab: 'Tab',
};
