var $name="analytics/getDataLayer",$path="app/modules/analytics/getDataLayer.js",$this={$name,$path,};import Log from 'app/modules/shell/Log';

const log = new Log($this);

function getDataLayer() {
  let { dataLayer } = window;
  if (dataLayer == null) {
    // eslint-disable-next-line no-multi-assign
    window.dataLayer = dataLayer = [];
    log.info('Google analytics data layer was not found, creating new');
  }
  return dataLayer;
}

export default getDataLayer;
