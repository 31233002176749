var $name="parseDecimal",$path="app/utilities/parseDecimal.js",$this={$name,$path,};/**
  * Parses string as decimal number and returns it.
  * Returns default value if string cannot be parsed.
  * @param { string } value
  * @param { number } [def]
  * @returns { number }
*/
// eslint-disable-next-line import/prefer-default-export
const parseDecimal = (value, def = 0) => {
  const decimal = parseInt(value, 10);
  return Number.isNaN(decimal) ? def : decimal;
};

export default parseDecimal;
