var $name="analytics/download",$path="app/modules/analytics/download.js",$this={$name,$path,};/**
 * @param { { documentURL: string } } variables
 */
function downloadProvider({
  documentURL,
}, config) {
  const { searchParams } = new URL(documentURL, window.location.href);
  return Object.fromEntries(
    config.downloadLinkParams.map(
      ([param, key]) => [key, searchParams.get(param)],
    ),
  );
}

// eslint-disable-next-line import/prefer-default-export
export { downloadProvider };
