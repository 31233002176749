var $class="se2--radio-btn",$name="radioBtn",$path="app/partials/radioBtn/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';
import { keyNames } from 'configs';
import { toggleInvalid } from 'app/partials/input';

const onChange = ({ target }) => {
  toggleInvalid(target, !target.checkValidity());
};

const handleKeyDown = (event) => {
  if (event.target.classList.contains('input-radio')) {
    const currentElement = event.target.closest('li');
    const firstInputElement = event.target.closest('ul').firstElementChild.firstElementChild;
    const lastInputElement = event.target.closest('ul').lastElementChild.firstElementChild;
    const nextInputElement = currentElement.nextElementSibling?.querySelector('input.input-radio');
    const previousInputElement = currentElement.previousElementSibling?.querySelector('input.input-radio');
    let stopBrowserEvents = false;

    switch (event.code) {
      case keyNames.arrowLeft:
      case keyNames.arrowUp:
        if (previousInputElement) {
          previousInputElement.focus();
          previousInputElement.checked = true;
        } else {
          lastInputElement.focus();
          lastInputElement.checked = true;
        }
        stopBrowserEvents = true;
        break;

      case keyNames.arrowRight:
      case keyNames.arrowDown:
        if (nextInputElement) {
          nextInputElement.focus();
          nextInputElement.checked = true;
        } else {
          firstInputElement.focus();
          firstInputElement.checked = true;
        }
        stopBrowserEvents = true;
        break;

      default:
        break;
    }

    if (stopBrowserEvents) {
      event.preventDefault();
    }
  }
};

const onKeyDown = (event) => {
  toggleInvalid(event.target, false);
  handleKeyDown(event);
};

export default shell.registerComponent($this, ({ mount, addEventListener }) => {
  mount((element) => {
    addEventListener(element, 'change', onChange);
    addEventListener(element, 'keydown', onKeyDown);
  });
});
