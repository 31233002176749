var $class="se2--header-se",$name="HeaderSE",$path="app/components/HeaderSE/config.js",$this={$class,$name,$path,};export const focusableElementsSelector = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
export const headerClassName = $class;
export const headerSelector = `.${$class}`;
export const metabarHeightValue = 40;
export const headerVersion8ClassName = 'header-version-8';
export const metabarHeightValueVersion8 = 32;
export const ariaExpandedAttribute = 'aria-expanded';
export const qdsBaseUrl = 'https://d2osz8slymlqdp.cloudfront.net/@quartzds';
export const qdsCoreVersion = '@1.0.0-beta.32';
export const qdsTokenVersion = '@1.0.0-beta.4';
export const qdsIconsCore = '@1.0.0-beta.7';
export const qdsIconsGeneral = '@1.0.0-beta.4';
export const qdsSeFont = '@1.0.0-beta.2';
export const qdsWebsite = '@1.0.0-beta.37';
export const enableQDS = 'No';// NOTE: Value 'Yes' will enable it by default but let it be through data.json value only
