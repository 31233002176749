var $class="se2--utilities",$name="utilities",$path="app/components/utilities/toggleFormValidity.js",$this={$class,$name,$path,};import { css } from 'configs';

const formGroupClassName = 'form-group';

const toggleFormValidity = (formElement, isValid) => formElement
  .closest(`.${formGroupClassName}`)
  .classList
  .toggle(
    css.classNames.invalid,
    !isValid,
  );

export default toggleFormValidity;
