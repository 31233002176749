var $class="se2--content-asset-blocks",$name="ContentAssetBlocks",$path="app/components/ContentAssetBlocks/config.js",$this={$class,$name,$path,};export const activeSlideClassName = 'active-slide';
export const contentClassName = 'animated-content-body';
export const controlsSelector = '.controls';
export const descriptionClassName = 'card-text';
export const headerSelector = '.slider-header';
export const nextButtonSelector = '.swiper-button-next';
export const prevButtonSelector = '.swiper-button-prev';
export const slideSelector = '.slide';
export const swiperContainerSelector = '.swiper-container';
export const uberflipContainerSelector = '.uberflip-cards';
