var $class="se2--utilities",$name="utilities",$path="app/components/utilities/toggleSnackbarMessage.js",$this={$class,$name,$path,};import { css } from 'configs';

const setSnackbarMessage = (text) => {
  const snackbarMessage = document.querySelector('.snackbar-description');
  snackbarMessage.innerHTML = text;
};

const toggleSnackbarMessage = (className, show) => {
  const snackbarContainerSeletor = '.snackbar-container';
  const bgSuccessClassName = 'bg-success';
  document.querySelector(snackbarContainerSeletor)?.classList.toggle('invisible', !show);
  if (show) {
    document.querySelector(snackbarContainerSeletor)?.classList.add(className);
    const checkSuccessClassExists = document.querySelector(snackbarContainerSeletor)?.classList
      .contains(bgSuccessClassName);
    const checkErrorClassExists = document.querySelector(snackbarContainerSeletor)?.classList.contains('bg-error');

    if (checkSuccessClassExists && className === bgSuccessClassName) {
      const getSuccessMessage = document.querySelector(snackbarContainerSeletor)?.dataset.successText;
      if (checkErrorClassExists) {
        document.querySelector(snackbarContainerSeletor)?.classList.remove('bg-error');
        document.querySelector('.error-icon')?.classList.add(css.classNames.hide);
      }
      document.querySelector('.success-icon')?.classList.remove(css.classNames.hide);
      setSnackbarMessage(getSuccessMessage);
    }

    if (checkErrorClassExists && className === 'bg-error') {
      const getErrorMessage = document.querySelector(snackbarContainerSeletor)?.dataset.errorText;
      if (checkSuccessClassExists) {
        document.querySelector(snackbarContainerSeletor)?.classList.remove(bgSuccessClassName);
        document.querySelector('.success-icon')?.classList.add(css.classNames.hide);
      }
      document.querySelector('.error-icon')?.classList.remove(css.classNames.hide);
      setSnackbarMessage(getErrorMessage);
    }
  }
};

export default toggleSnackbarMessage;
