var $name="ajax",$path="app/modules/ajax.js",$this={$name,$path,};import shell from 'app/modules/shell';
import watchDOMMutations from 'app/modules/shell/engine/watchDOMMutations';
import { s3 } from 'configs/delays';

export default shell.registerModule($this, ({ log }) => {
  async function fetchData(url, init, fail = true) {
    log.info('Sending request to "%s" with options: %o', url, init);
    try {
      const response = await fetch(url, init);
      // https://github.com/github/fetch/blob/v3.0.0/fetch.js#L393
      const { status, statusText } = response;
      log[response.ok ? 'info' : 'error'](
        'Request to "%s" %s with status: %s (%s)',
        url,
        response.ok ? 'completed' : 'failed',
        status,
        statusText,
      );
      if (!response.ok && fail) throw new Error(statusText);
      return response;
    } catch (error) {
      log.error('Request to "%s" failed with error:', url, error);
      throw error;
    }
  }

  async function fetchDataResponse(url, init) {
    log.info('Sending request to "%s" with options: %o', url, init);
    try {
      const response = await fetch(url, init);
      // https://github.com/github/fetch/blob/v3.0.0/fetch.js#L393
      const { status, statusText } = response;
      if (response.ok || [400, 401, 404].includes(status)) {
        log[response.ok ? 'info' : 'error'](
          'Request to "%s" %s with status: %s (%s)',
          url,
          response.ok ? 'completed' : 'failed',
          status,
          statusText,
        );
      } else throw new Error(statusText);
      return response;
    } catch (error) {
      log.error('Request to "%s" failed with error:', url, error);
      throw error;
    }
  }

  /**
   * Fetches JSON data from the network.
   * @param { string } url
   * @param { RequestInit } [init]
   * @returns { Promise<any> }
   * @see https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch
   */
  async function fetchJSON(url, init = {}) {
    try {
      const response = await fetchData(url, init);
      return await response.json();
    } catch (error) {
      log.error('Response from "%s" did not deliver JSON data:', url, error);
      throw error;
    }
  }

  // With the help of this method you can get both json object and status code from the response

  async function fetchResponse(url, init = {}) {
    try {
      const response = await fetchDataResponse(url, init);
      return await response;
    } catch (error) {
      log.error('Response from "%s" did not deliver JSON data:', url, error);
      throw error;
    }
  }

  /**
   * Fetches text data from the network.
   * @param { string } url
   * @param { RequestInit } [init]
   * @returns { Promise<string> }
   * @see https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch
   */
  async function fetchText(url, init = {}) {
    try {
      const response = await fetchData(url, init);
      return await response.text();
    } catch (error) {
      log.error('Response from "%s" did not deliver text data:', url, error);
      throw error;
    }
  }

  /**
   * Fetches response status from the network.
   * @param { string } url
   * @param { RequestInit } [init]
   * @returns { Promise<number> }
   * @see https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch
   */
  async function fetchStatus(url, init = {}) {
    try {
      const response = await fetchData(url, init, false);
      return response.status;
    } catch (error) {
      log.error('Response from "%s" did not deliver status:', url, error);
      throw error;
    }
  }

  /**
   * Fetches deferral markup from the network and inserts it into the target element.
   * @param { string } url
   * @param { HTMLElement } element
   * @param { RequestInit } [init]
   * @see https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch
   */
  async function fetchDeferralTo(url, element, init = {}) {
    let disconnect;

    try {
      if (element.dataset.deferralLoaded === 'true') return;
      disconnect = watchDOMMutations();

      element.innerHTML = await fetchText(url, init);
      element.dataset.deferralLoaded = 'true';
    } catch (error) {
      log.error('Response from "%s" did not deliver deferral markup:', url, error);
      throw error;
    } finally {
      setTimeout(disconnect, s3);
    }
  }

  return { fetchDeferralTo, fetchJSON, fetchText, fetchStatus, fetchResponse };
});
