var $class="se2--utilities",$name="utilities",$path="app/components/utilities/onFormSubmitMessage.js",$this={$class,$name,$path,};import { statuses } from 'configs';

function onFormSubmitMessage(form, formId, status, trackAnalytics) {
  if (
    form.id === formId
    && (status === statuses.accepted || status === statuses.redirected)
  ) {
    trackAnalytics?.();
    form.reset();
  }
}

export default onFormSubmitMessage;
