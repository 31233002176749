var $class="se2--utilities",$name="utilities",$path="app/components/utilities/toogleControlVisibility.js",$this={$class,$name,$path,};import reduce from 'lodash/reduce';
import parseDecimal from 'app/utilities/parseDecimal';
import { css } from 'configs';

const toogleControlVisibility = (items, itemsContainer, controls) => {
  const cardsContainerWidth = reduce(items,
    (value, item) => value
      + item.offsetWidth
      + parseDecimal(getComputedStyle(item).marginRight),
    (itemsContainer?.getBoundingClientRect().x - document.body.getBoundingClientRect().x));

  controls?.classList.toggle(
    css.classNames.hide, cardsContainerWidth < document.body.offsetWidth,
  );
};

export default toogleControlVisibility;
