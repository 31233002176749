var $class="se2--select",$name="select",$path="app/partials/select/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

import { toggleInvalid } from 'app/partials/input';

export default shell.registerComponent($this, ({
  addEventListener, configs, mount,
}) => {
  const toggleSelected = (element) => {
    element.classList.toggle('selected', !!element.value);
  };

  const onChange = ({ target }) => {
    toggleInvalid(target, !target.checkValidity());
    toggleSelected(target);
    const concatenateFieldValue = target.dataset.concatenateField || target.name;
    if (concatenateFieldValue) {
      const conditionalFieldElements = target.closest('form')
        ?.querySelectorAll(`[data-field-name='${concatenateFieldValue}']`);

      conditionalFieldElements?.forEach((element) => {
        element.classList.toggle(configs.css.classNames.hide, element.dataset.fieldValue !== target.value);
        const selectElement = element.querySelector('select, input');
        selectElement.disabled = element.dataset.fieldValue !== target.value;
      });
    }
  };

  const triggerOnChangeEvent = (element) => {
    const selectElement = element.querySelector('select');
    if (selectElement.value) {
      onChange({ target: selectElement });
    }
  };

  const onKeyDown = ({ target }) => {
    toggleInvalid(target, false);
  };

  mount((element) => {
    toggleSelected(element.querySelector('select'));
    addEventListener(element, 'change', onChange);
    addEventListener(element, 'keydown', onKeyDown);
    triggerOnChangeEvent(element);
  });
});
