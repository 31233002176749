var $name="analytics/pageview",$path="app/modules/analytics/pageview.js",$this={$name,$path,};// import getSecondLevelDomain from 'app/utilities/getSecondLevelDomain';
import lookupSlugs from 'app/utilities/lookupSlugs';

import getEnvironment from './getEnvironment';

/**
 * @param { { articleTitle: string}  } variables
 */
function pageviewProvider({
  articleTitle = '',
  pageSubSubCategoryKey = '',
} = {}, config, settings) {
  const { taxonomy, languageCode } = settings.page;
  let { countryCode } = settings.page;
  // const domain = getSecondLevelDomain(window.location) ?? window.location.hostname;
  const [
    taxonomyTemplate = '',
    taxonomyTopCategory = '',
    taxonomyCategory = '',
    taxonomySubCategory = '',
  ] = taxonomy;

  if (countryCode === 'africa') {
    countryCode = 'xf';
  }
  if (countryCode === 'uk') {
    countryCode = 'gb';
  }

  const {
    businessUnit = {},
    digitalPlatform = {},
    fromWebView = false,
    pageCategory = {},
    pageCategoryId = {},
    pageProductId = {},
    pageRangeId = {},
    pageSubCategory = {},
    pageSubCategoryId = {},
    pageSubSubCategory = {},
    pageTopCategory = {},
    template = {},
  } = config;

  return {
    articleTitle,
    businessUnit: lookupSlugs(businessUnit, taxonomy),
    digitalPlatform,
    digitalPlatformCountry: countryCode.toUpperCase(),
    environment: getEnvironment(config),
    fromWebView,
    page: window.location.href,
    pageCategory: lookupSlugs(pageCategory, taxonomy, taxonomyCategory),
    pageCategoryId: lookupSlugs(pageCategoryId, taxonomy),
    pageLanguage: languageCode.toUpperCase(),
    pageProductId: lookupSlugs(pageProductId, taxonomy),
    pageRangeId: lookupSlugs(pageRangeId, taxonomy),
    pageSubCategory: lookupSlugs(pageSubCategory, taxonomy, taxonomySubCategory),
    pageSubCategoryId: lookupSlugs(pageSubCategoryId, taxonomy),
    pageSubSubCategory: pageSubSubCategory[pageSubSubCategoryKey] ?? '',
    pageTitle: document.title,
    pageTopCategory: lookupSlugs(pageTopCategory, taxonomy, taxonomyTopCategory),
    template: lookupSlugs(template, taxonomy, taxonomyTemplate),
  };
}

// eslint-disable-next-line import/prefer-default-export
export { pageviewProvider };
