var $name="analytics/exitToSchneider",$path="app/modules/analytics/exitToSchneider.js",$this={$name,$path,};import getEnvironment from './getEnvironment';

/**
 * @param { { linkUrl: string}  } variables
 */
function exitToSchneiderProvider({
  linkUrl = '',
} = {}, config, settings) {
  let { countryCode } = settings.page;

  if (countryCode === 'africa') {
    countryCode = 'xf';
  }
  if (countryCode === 'uk') {
    countryCode = 'gb';
  }

  return {
    linkUrl,
    digitalPlatform: config.digitalPlatform,
    digitalPlatformCountry: countryCode.toUpperCase(),
    environment: getEnvironment(config),
  };
}

// eslint-disable-next-line import/prefer-default-export
export { exitToSchneiderProvider };
