var $name="getVideoId",$path="app/utilities/getVideoId.js",$this={$name,$path,};import includes from 'lodash/includes';

/**
 * Returns YouTube video ID
 * @param {HTMLElement} playerNode - DOM node which contains data attribute of link to video
 * @param {string} dataAttributeName - name of data attribute which contains link to video
 */

const getVSearchParameter = (url) => new URLSearchParams(url).get('v');

export default function getVideoId(playerNode, dataAttributeName = 'videolink') {
  let videoSrc = null;
  let videoId = null;

  if (playerNode && playerNode.dataset[dataAttributeName]) {
    videoSrc = playerNode.dataset[dataAttributeName];
    // Transform source string into YouTube id
    videoSrc = videoSrc.split('/').pop();

    // according with comment on SDLRF-7134 add support for thumbnails for URL with 'videoseries' and 'rel=0'
    if (includes(videoSrc, 'videoseries')) {
      videoId = getVSearchParameter(videoSrc);
    } else if (includes(videoSrc, 'watch')) {
      const params = videoSrc.split('?');
      videoId = getVSearchParameter(params[1]);
    } else {
      [videoId] = videoSrc.split('?');
    }
  }

  return videoId;
}
