var $name="shell/analyze/index",$path="app/modules/shell/analyze/index.js",$this={$name,$path,};const createAsyncInvoker = (importer) => {
  let promise;
  return {
    get() {
      return (...args) => {
        (promise ?? (promise = importer())).then(
          (module) => module.default(...args),
        );
      };
    },
  };
};

const analyze = Object.create(
  null,
  {
    performance: createAsyncInvoker(() => import('./performance')),
    stylesheets: createAsyncInvoker(() => import('./stylesheets')),
  },
);

export default analyze;
