var $class="se2--utilities",$name="utilities",$path="app/components/utilities/checkCheckboxGroupValidity.js",$this={$class,$name,$path,};import every from 'lodash/every';

import checkCheckboxValidity from './checkCheckboxValidity';

const requiredCheckboxContainerSelector = '.checkbox-container[data-required]';

const checkCheckboxGroupValidity = (form) => every(
  form.querySelectorAll(requiredCheckboxContainerSelector),
  checkCheckboxValidity,
);

export default checkCheckboxGroupValidity;
