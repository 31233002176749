var $name="analytics/search",$path="app/modules/analytics/search.js",$this={$name,$path,};/**
 * @param {
 * {
 * serialNumber: string,
 * }
 * } variables
 * @returns
 */

const searchProvider = ({
  serialNumber = '',
}) => {
  const formParams = {};

  formParams.event = 'search';
  formParams.site_search_category = 'Warranty Lookup Tool';
  formParams.search_term = serialNumber;

  return {
    ...formParams,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { searchProvider };
