var $class="se2--utilities",$name="utilities",$path="app/components/utilities/checkCheckboxValidity.js",$this={$class,$name,$path,};import validateCheckboxes from './validateCheckboxes';
import toggleFormValidity from './toggleFormValidity';

function checkCheckboxValidity(checkboxContainerElement) {
  const isValid = validateCheckboxes(checkboxContainerElement);
  toggleFormValidity(checkboxContainerElement, isValid);

  return isValid;
}

export default checkCheckboxValidity;
