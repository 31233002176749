var $name="analytics/newsletter",$path="app/modules/analytics/newsletter.js",$this={$name,$path,};import getSlugs from 'app/utilities/getSlugs';
import lookupSlugs from 'app/utilities/lookupSlugs';

/**
 * @param { { siteLocationKey: string } } variables
 */
const newsletterSubscriptionProvider = ({
  siteLocationKey,
}, config) => ({
  siteLocation: lookupSlugs(
    config.siteLocation ?? {},
    getSlugs(siteLocationKey),
    siteLocationKey,
  ),
});

// eslint-disable-next-line import/prefer-default-export
export { newsletterSubscriptionProvider };
