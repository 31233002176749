var $name="shell/engine/watchBreakpoints",$path="app/modules/shell/engine/watchBreakpoints.js",$this={$name,$path,};import { breakpoints, messages } from 'configs';

import messaging from '../messaging';
import settings from '../settings';

/**
 * Watches all breakpoints and publishes message when current breakpoint changes
 */
export default function watchBreakpoints() {
  Object.entries(breakpoints).forEach(
    ([key, { mediaQuery }]) => {
      const mediaQueryList = window.matchMedia(mediaQuery);
      window.matchMedia(mediaQuery).addEventListener('change', () => {
        if (mediaQueryList.matches) {
          messaging.publishMessage(messages.breakpoint, key);
        }
      });
      if (mediaQueryList.matches) {
        settings.subscribers[messages.breakpoint]?.(key);
      }
    },
  );
}
