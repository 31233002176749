var $class="se2--content-lookup--modal-lookup",$name="ContentLookup/modalLookup",$path="app/components/ContentLookup/partials/modalLookup/config.js",$this={$class,$name,$path,};export const modalClassName = $class;
export const closeButtonClassName = 'close-btn';
export const expandButtonClassName = 'expand-btn';
export const chartInfoBlockClassName = 'chart-subtitle-wrap';
export const modalElementClassName = 'modal-element';
export const modalWrapClassName = 'modal-wrap';
export const modalWrapOverlayClassName = 'wrap-modal-overlay';
export const placeholderClassName = 'modal-placeholder';
export const separatorClassName = 'separator';

export default {
  closeButtonClassName,
  expandButtonClassName,
  chartInfoBlockClassName,
  modalClassName,
  modalElementClassName,
  modalWrapClassName,
  modalWrapOverlayClassName,
  placeholderClassName,
  separatorClassName,
};
