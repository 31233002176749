var $name="shell/registry/VisualUnit",$path="app/modules/shell/registry/VisualUnit.js",$this={$name,$path,};import Unit from './Unit';

export default class VisualUnit extends Unit {
  elements = [];

  dispose() {
    const { elements } = this;
    this.elements.length = 0;
    elements.forEach(
      (element) => super.unmount(element),
    );
    super.dispose();
  }

  mount(element) {
    this.scope = element;
    this.elements.push(element);
    super.mount(element);
    this.scope = null;
  }

  unmount(element) {
    this.elements.splice(this.elements.indexOf(element), 1);
    super.unmount(element);
  }
}
