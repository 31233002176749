var $name="getCookie",$path="app/utilities/getCookie.js",$this={$name,$path,};/**
 * Finds a cookie by its name and returns its value.
 * Returns null if cookie was nit found.
 * @param { string } name
 */
function getCookie(name) {
  if (!name) return null;

  // Escaping special characters
  const safeName = name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1');

  // Looking for substring like "cookie_name=cookie_value"
  const matches = document.cookie.match(new RegExp(`(?:^|; )${safeName}=([^;]*)`));

  return matches ? decodeURIComponent(matches[1]) : null;
}

export default getCookie;
