var $class="se2--textarea",$name="textarea",$path="app/partials/textarea/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';
import { toggleInvalid } from 'app/partials/input';

const onChange = ({ target }) => {
  toggleInvalid(target, !target.checkValidity());
};

const onKeyDown = ({ target }) => {
  toggleInvalid(target, false);
};

export default shell.registerComponent($this, ({ addEventListener, mount }) => {
  mount((element) => {
    addEventListener(element, 'change', onChange);
    addEventListener(element, 'keydown', onKeyDown);
  });
});
