var $name="sticky/initializeSticky",$path="app/modules/sticky/initializeSticky.js",$this={$name,$path,};import Log from 'app/modules/shell/Log';
import messaging from 'app/modules/shell/messaging';

import parseDecimal from 'app/utilities/parseDecimal';

import { messages } from 'configs';

import throttle from 'lodash/throttle';
import forEach from 'lodash/forEach';

import calculateTotalStickyHeight from './calculateTotalStickyHeight';
import recalculateStickyPositions from './recalculateStickyPositions';

const log = new Log($this);
window.stickyElements = [];

function calculateDistanceToElement(element) {
  const elementPostition = element.getBoundingClientRect().top;
  const elementTopIndent = parseDecimal(element.style.top);
  element.dataset.stuck = elementPostition <= elementTopIndent;
}

function onScroll() {
  forEach(window.stickyElements, calculateDistanceToElement);
}

function initializeSticky(element) {
  if (window.stickyElements.includes(element)) return;
  if (element?.dataset.sticky === 'true') window.stickyElements.push(element);

  if (!window.stickyElements.length) {
    log.warn('No sticky elements on a page');
    return;
  }

  // Sort sticky elements according their order that are set in data-sticky-order attribute
  window.stickyElements.sort(
    (a, b) => a.dataset.stickyOrder - b.dataset.stickyOrder,
  );

  log.info('Sticky elements: %o', window.stickyElements);

  const elementObserver = new MutationObserver(([mutationRecord]) => {
    const { target, oldValue } = mutationRecord;
    if (target.dataset.stuck !== oldValue) {
      calculateTotalStickyHeight();
    }
  });

  elementObserver.observe(element, {
    attributeFilter: ['data-stuck'],
    attributeOldValue: true,
  });

  recalculateStickyPositions();
  calculateDistanceToElement(element);
}

document.addEventListener('scroll', onScroll);
messaging.subscribeToMessage(messages.smartbanner, ({ isOpen }) => {
  if (!isOpen) recalculateStickyPositions();
});
window.addEventListener('resize', throttle(recalculateStickyPositions, 250));

export default initializeSticky;
