var $name="utilities/toMediaQuery",$path="configs/utilities/toMediaQuery.js",$this={$name,$path,};/**
 * creates string value for media attributes
 * @example
 * toMediaQuery({ max: source.maxWidth, min: source.minWidth});
 */

function toMediaQuery({ max = 0, min = 0 }) {
  let query = '';
  if (max) query += `(max-width: ${max}px)`;
  if (min) {
    if (query) query += ' and ';
    query += `(min-width: ${min}px)`;
  }
  return query;
}

module.exports = toMediaQuery;
