var $name="sso",$path="configs/sso.js",$this={$name,$path,};const config = {
  configEndpointURL: 'product/cache/model/com/schneider/rest/IdmsConfigsActor/getConfigs',
  cookieExpire: 60 * 60 * 24 * 90, // 90 days
  idTokenCookieName: 'se_token',
  keysEndpointURL: 'product/cache/model/com/schneider/rest/PublicKeysActor/getKeys',
  nonAuthorizedUserType: 'anonymous',

  // country and language codes are hardcoded here, because the login endpoint is available only on /fr/fr locale.
  redirectEndpointURL: '/fr/fr/product/login',
  seamlessOverlayClassName: '.seamless-overlay',
  userTypeCookieName: 'se_user_type',
  userDataLocalStorageName: 'personalized_user_data',
};

const oidcSettings = {
  authority: null,
  automaticSilentRenew: false,
  client_id: null,
  extraQueryParams: {
    app: 'PESV2',
  },
  filterProtocolClaims: true,
  idTokenCookieKey: config.idTokenCookieName,
  includeIdTokenInSilentRenew: true,
  jwks_uri: null,
  loadUserInfo: false,
  metadataUrl: '/identity/services/apexrest/oidcsilent/autodiscovery',
  post_logout_redirect_uri: null,
  response_type: 'code',
  silentRequestTimeout: 10000,
  silent_redirect_uri: null,
  userDataLocalStorageKey: config.userDataLocalStorageName,
};

module.exports = {
  ...config,
  oidcSettings,
};
