var $class="se2--utilities",$name="utilities",$path="app/components/utilities/handleSlidesNavigation.js",$this={$class,$name,$path,};import settings from 'app/modules/shell/settings';

import isUsingKeyboard from 'app/utilities/isUsingKeyboard';
import setTabIndex from 'app/utilities/setTabIndex';
import switchFocusToTargetElement from 'app/utilities/switchFocusToTargetElement';

import { directions, keyNames } from 'configs';

import forEach from 'lodash/forEach';

import { slideSelector } from 'app/components/ContentAssetBlocks/config';

function handleSlidesNavigation(event, prevButton, nextButton, withInteractiveElements = false) {
  const setTabIndexForInteractiveElements = (target, participate) => {
    forEach(
      target.closest(slideSelector)?.querySelectorAll('a, button'),
      (el) => setTabIndex(el, participate),
    );
  };

  if (isUsingKeyboard()) {
    const { code, target } = event;
    let { arrowLeft, arrowRight } = keyNames;

    if (settings.direction === directions.rtl) {
      [arrowLeft, arrowRight] = [arrowRight, arrowLeft];
    }

    if (code === arrowRight) {
      event.preventDefault();
      withInteractiveElements && setTabIndexForInteractiveElements(target, false);
      const nextElement = target.closest(slideSelector)?.nextElementSibling;
      if (nextElement) switchFocusToTargetElement(nextElement, target);
      nextButton?.click();
    } else if (code === arrowLeft) {
      event.preventDefault();
      withInteractiveElements && setTabIndexForInteractiveElements(target, false);
      const previousElement = target.closest(slideSelector)?.previousElementSibling;
      if (previousElement) switchFocusToTargetElement(previousElement, target);
      prevButton?.click();
    } else if (code === keyNames.tab) {
      setTabIndexForInteractiveElements(target, withInteractiveElements);
    }
  }
}

export default handleSlidesNavigation;
