var $name="shell/registry/Unit",$path="app/modules/shell/registry/Unit.js",$this={$name,$path,};import forEach from 'lodash/forEach';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';

import configs from 'configs';
import { activateEvent, mountEvent, unmountEvent } from './events';
import Log from '../Log';
import Performance from '../Performance';
import schedule from '../schedule';
import settings from '../settings';

/**
 * @param { Function } callback
 */
function validateCallback(callback) {
  if (!isFunction(callback)) {
    throw new Error('Callback must be a function');
  }
}

export default class Unit {
  callbacks = {};
  /** @type { Map<Object, Array<Function>> } */
  disposers = new Map();
  scope = null;

  constructor(source, callback) {
    this.callbacks[activateEvent] = callback;
    this.log = new Log(source);
    this.performance = new Performance(source);
    this.source = source;
  }

  /**
   * @param { Function } callback
   */
  addDisposer(callback) {
    validateCallback(callback);
    const { disposers } = this;
    if (disposers.has(this.scope)) {
      disposers.get(this.scope).push(callback);
    } else {
      disposers.set(this.scope, [callback]);
    }
  }

  activate() {
    const exports = this.invokeCallback(activateEvent, this.context) ?? {};
    if (!isObject(exports)) {
      throw new Error(
        'Component, module or partial must not return from registration callback or return an object',
      );
    }
    this.exports = Object.defineProperties(
      exports,
      Object.getOwnPropertyDescriptors(this.source),
    );
  }

  /**
   * @param { string } event
   * @param { Function } callback
   */
  assignCallback(event, callback) {
    this.verifyCallback(event, callback);
    this.callbacks[event] = callback;
  }

  createContext(Context, parent) {
    this.context = Object.freeze(
      Object.defineProperties(new Context(parent), {
        configs: { value: configs },
        schedule: { value: schedule },
        settings: { value: settings },
      }),
    );
  }

  dispose() {
    this.disposers.forEach(this.invokeDisposers.bind(this));
    this.disposers.clear();
  }

  /**
   * @param { string } event
   * @param { any } [argument]
   */
  invokeCallback(event, argument) {
    const { callbacks, log } = this;
    const callback = callbacks[event];
    if (callback) {
      if (argument == null) log.info(event);
      else log.info(`${event}\n`, argument);
      return callback(argument);
    }
    return undefined;
  }

  invokeDisposers(disposers) {
    forEach(
      disposers,
      (disposer) => {
        try {
          disposer();
        } catch (error) {
          this.log.error('Unable to invoke disposer:', error);
        }
      },
    );
  }

  /**
   * @template T
   * @param { T } invoker
   * @param { Function } disposer
   * @returns { T }
   */
  makeDisposable(invoker, disposer) {
    return (...args) => {
      this.addDisposer(() => disposer(...args));
      return invoker(...args);
    };
  }

  mount(element) {
    this.invokeCallback(mountEvent, element);
  }

  unmount(element) {
    this.invokeCallback(unmountEvent, element);
    this.invokeDisposers(this.disposers.get(element));
  }

  /**
   * @param { string } event
   * @param { Function } callback
   */
  verifyCallback(event, callback) {
    validateCallback(callback);
    const existing = this.callbacks[event];
    if (existing != null && existing !== callback) {
      throw new Error(`Another callback has already been registered for the "${event}" event`);
    }
  }
}
