var $name="shell/Performance",$path="app/modules/shell/Performance.js",$this={$name,$path,};import { namespaces } from 'configs';

const scope = ({ $path }, name) => `${namespaces.public}://${$path}/${name}`;

/**
 * @implements { IPerformance }
 */
class Performance {
  constructor(source) {
    Object.defineProperty(this, 'source', { value: source });
  }

  mark(name) {
    window.performance.mark(scope(this, name));
  }

  measure(name, start, end) {
    window.performance.measure(
      scope(this, name),
      start === undefined ? undefined : scope(this, start),
      end === undefined ? undefined : scope(this, end),
    );
  }
}

export default Performance;
